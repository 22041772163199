//import * as Sentry from '../sentry.init';
import grFetch from './fetch';
import HandledPromise from "./handled-promise";
import JSON from 'circular-json';

let Storage = (context) => {
    let self = {};
    let cache = {};

    self.serverStorageBackend = () => {
         let endpoint = 'https://kv.garderobo.ai';

        /*Sentry.addBreadcrumb({
            category: 'storage',
            message: 'Selected ServerStorage as storage backend',
            level: Sentry.Severity.Info
        });*/
        self.backendType = 'server-storage';

        return {
            setItem: function(key, value) {
                return new Promise((resolve, reject) => {
                    grFetch.apply(context, [endpoint + '/' + key, {
                        type: 'post',
                        data: value,
                        dataType: 'application/json',
                        checkInit: false,
                        checkSession: false
                    }]).then(data => {
                        resolve(data);
                    }).catch(reject);
                });
            },
            getItem: function(key) {
                return new Promise((resolve, reject) => {
                    grFetch.apply(context, [endpoint + '/' + key, {
                        dataType: 'application/json',
                        checkInit: false,
                        checkSession: false
                    }]).then(data => {
                        data = JSON.parse(data);
                        if(data !== null) resolve(data);
                        else reject();
                    }).catch(reject);
                });
            },
            removeItem: function(key) {
                return new Promise((resolve, reject) => {
                    grFetch.apply(context, [endpoint + '/' + key, {
                        type: 'delete',
                        dataType: 'application/json',
                        checkInit: false,
                        checkSession: false
                    }]).then(data => {
                        resolve(data);
                    }).catch(reject);
                });
            }
        }
    };

    self.sessionStorageBackend = () => {
        /*Sentry.addBreadcrumb({
            category: 'storage',
            message: 'Selected SessionStorage as storage backend',
            level: Sentry.Severity.Info
        });*/
        self.backendType = 'local-storage';

        return browserStorageBackend(window.sessionStorage);
    };

    self.localStorageBackend = () => {
        /*Sentry.addBreadcrumb({
            category: 'storage',
            message: 'Selected LocalStorage as storage backend',
            level: Sentry.Severity.Info
        });*/
        self.backendType = 'session-storage';

        return browserStorageBackend(window.localStorage);
    };

    let browserStorageBackend = (storage) => {
        return {
            setItem: function(key, value) {
                return new Promise((resolve) => {
                    resolve(storage.setItem(key, value));
                });
            },
            getItem: function(key) {
                return new Promise((resolve, reject) => {
                    let value = storage.getItem(key);
                    if(value) resolve(value);
                    else reject();
                });
            },
            removeItem: function(key) {
                return new Promise((resolve) => {
                    resolve(storage.removeItem(key));
                });
            }
        }
    };

    self.backend = (() => {
        try {
            window.localStorage.setItem('_testLocalStorageAvailability', 'testing');
            window.localStorage.removeItem('_testLocalStorageAvailability');

            return self.localStorageBackend();
        } catch(e) {
            try {
                window.sessionStorage.setItem('_testSessionStorageAvailability', 'testing');
                window.sessionStorage.removeItem('_testSessionStorageAvailability');

                return self.sessionStorageBackend();
            } catch(e) {
                try {
                    return self.serverStorageBackend();
                } catch (e) {
                    throw new Error('Unable to store user data');
                }
            }
        }
    })();
    self.backendType = null;

    self.cacheMediator = {
        setItem: (key, type, value) => {
            return self.backend.setItem(key, self.transformer(type, true, value)).then(() => {
                console.log('Set item: '+key+' value: '+value);
                cache[key] = value;
            });
        },
        getItem: (key, type, path) => {
            return new Promise((resolve, reject) => {
                if(cache.hasOwnProperty(key)) resolve(cache[key]);
                else self.backend.getItem(key).then(value => {
                    resolve(self.transformer(type, false, value));
                }).catch(reject);
            })
        },
        removeItem: (key) => {
            return self.backend.removeItem(key).then(() => {
                if(cache.hasOwnProperty(key)) delete cache[key];
            });
        }
    };

    self.transformer = (type, isSetOperation, data) => {
        try {
            switch (type) {
                case 'json':
                    return isSetOperation ? JSON.stringify(data) : JSON.parse(data);
                case 'date':
                    if(Date.parse(data) != Date.parse(data)) throw Error('Invalid date');
                    return isSetOperation ? data.toString() : new Date(data);
                default:
                    return data;
            }
        } catch(e) {
            return null;
        }
    };

    self.context = context;

    self.key = (key, type) => {
        let _key = {};

        _key.set = (value) => self.cacheMediator.setItem(key, type, value);

        _key.get = () => self.cacheMediator.getItem(key, type);

        _key.remove = () => self.cacheMediator.removeItem(key);

        return _key;
    };

    return self;
};

export default Storage;
import { Texts } from '../const';
import Utils from './utils';

export default class LooksWithSimilarsPopup {
    constructor(block, page) {
        this.block = block;
        this.items = block.products; //[items[0], items[1], items[2], items[3]];
        this.sliderPos = 0;
        this.page = page;
        this.similars = {};
        this.getGtmIdParam = page.getGtmIdParam;
        this.ecommerce = page.ecommerce;
    }

    drawPreloader() {
        const el = document.createElement('div');
        el.classList.add('garderobo-widget-popup__preloader');
        el.innerHTML = '<div class="lds-dual-ring"></div>';

        const container = document.querySelector('.garderobo-widget-popup-list-container');
        container.appendChild(el);
    }

    removePreloader() {
        const preloader = document.querySelector('.garderobo-widget-popup__preloader');
        if (preloader) {
            preloader.remove();
        }
    }

    drawLooksPopup() {
        const underlay = document.createElement('div');
        underlay.classList.add('garderobo-widget-popup-container');

        const popup = document.createElement('div');
        popup.classList.add('garderobo-widget-popup');
        popup.classList.add('garderobo-looks-similars-popup');

        const closeBtn = document.createElement('button');
        closeBtn.type = 'button';
        closeBtn.classList.add('garderobo-widget-popup__btn-close');

        closeBtn.addEventListener('click', this.closePopup);
        popup.appendChild(closeBtn);

        const content = document.createElement('div');
        content.classList.add('garderobo-widget-popup-content');
        popup.appendChild(content);

        const listContainer = document.createElement('div');
        listContainer.classList.add('garderobo-widget-popup-list-container');
        content.appendChild(listContainer);

        const listHeader = document.createElement('div');
        listHeader.classList.add('garderobo-widget-popup-list-header');
        listHeader.innerHTML = `<div class="garderobo-widget-popup-list-subheader">${this.page.getText(Texts.TOTAL_LOOK)}</div>
        <div class="garderobo-widget-popup-list-subheader">Swap it</div>`;
        listContainer.appendChild(listHeader);

        const listContent = document.createElement('ul');
        listContent.classList.add('garderobo-widget-popup-list-content');
        listContainer.appendChild(listContent);

        underlay.appendChild(popup);

        // todo remove
        window.addEventListener('resize', () => {
            this.setPopupHeight();
        });

        return underlay;
    }

    setPopupHeight(callback) {
        setTimeout(() => {
            const popup = document.querySelector('.garderobo-looks-similars-popup');

            if (window.innerWidth >= 768) {
                const rowItems = document.querySelectorAll('.garderobo-widget-popup-list-item-container');
                const headerHeight = 61;
                let rowItemsHeight = 0;

                if (popup && rowItems) {
                    rowItems.forEach((el, i) => {
                        if (i < 4) {
                            rowItemsHeight += el.offsetHeight;
                        }
                        if (i === 4) {
                            rowItemsHeight += 50;
                        }
                    });
                    popup.style.height = `${headerHeight + rowItemsHeight}px`;
                }
            } else if (popup) {
                popup.style.height = '100%';
            }

            if (callback) {
                callback();
            }
        });
    }

    openPopup(product) {
        // console.log('look', lookId);
        // console.log('__item', item);
        const popupEl = document.querySelector('.garderobo-widget-popup-container');
        if (popupEl) {
            const closeHandler = this.closePopup;
            const onClosePopup = function (e) {
                e.stopImmediatePropagation();
                if (e.target !== this) return;

                popupEl.removeEventListener('click', onClosePopup);
                closeHandler();
            };
            popupEl.addEventListener('click', onClosePopup);
            popupEl.classList.add('garderobo-widget-popup-container--opened');

            this.drawRightItems(this.block);

            this.drawItemsForSwap(this.block);
            this.sendGtm(this.getEcommerceLookDataList(this.block), 'Product Impressions');

            this.sendGtm(this.getEcommerceLookPromoClick(this.block), 'Promotion Clicks');

            this.drawPreloader();

            this.setPopupHeight();
        }
    }

    closePopup() {
        const popupEl = document.querySelector('.garderobo-widget-popup-container');
        if (popupEl) {
            popupEl.classList.remove('garderobo-widget-popup-container--opened');
        }
    }

    drawRightItems(look) {
        const container = document.querySelector('.garderobo-widget-popup-list-content');
        container.innerHTML = null;

        for (let i = 0; i < look.products.length; i++) {
            this.drawRightItem(look.products[i], look.look_id, container, look.items_ids[i], false, look, i);
        }
    }

    drawRightItem(product, lookId, container, productId, dontRender, look, productIndex) {
        // console.log('------', product);

        const listItemContainer = document.createElement('li');
        listItemContainer.classList.add('garderobo-widget-popup-list-item-container');
        container.appendChild(listItemContainer);

        const listItem = document.createElement('div');
        listItem.classList.add('garderobo-widget-popup-list-item');
        listItem.setAttribute('data-product-item-id', product.id);

        this.page.setProductAttributes(listItem, product, productIndex);

        const swapListItem = document.createElement('div');
        swapListItem.classList.add('garderobo-widget-popup-swap-list-item');

        if (!dontRender) {
            listItemContainer.appendChild(listItem);
            listItemContainer.appendChild(swapListItem);
        }

        const picItem = document.createElement('div');
        picItem.classList.add('garderobo-widget-popup-list-item-pic');
        listItem.appendChild(picItem);

        const imgWrapper = document.createElement('div');
        imgWrapper.classList.add('garderobo-widget-popup-list-item-img-wrapper');
        picItem.appendChild(imgWrapper);

        let imgUrl;
        if (product.link) {
            imgUrl = document.createElement('a');

            // UTM stuff
            let link = product.link;
            let template = this.page.getWidgetParam('utm_template');
            if (template) {
                link +=
                    (link.indexOf('?') === -1 ? '?' : '&') +
                    template.replace('{block_type}', 'looks').replace('{page_type}', this.page._widget.getPageSource).replace('{yml_id}', product.yml_id);
            }

            imgUrl.href = link;
            imgUrl.addEventListener('click', () => {
                this.ecommerce.productClickPopup(product, look);
                let wareId = product.wareId ? product.wareId : product.id;
                this.page._widget.analytics.sendEventWidgetClick(wareId, 'looks', this.page._widget.getPageSource());
            });
            imgWrapper.appendChild(imgUrl);
        }

        const imgItem = document.createElement('img');
        imgItem.classList.add('garderobo-widget-popup-list-item-img');
        imgItem.src = product.picture;
        if (imgUrl) {
            imgUrl.appendChild(imgItem);
        } else {
            imgWrapper.appendChild(imgItem);
        }

        // todo - outfit-swap-container

        const textItem = document.createElement('div');
        textItem.classList.add('garderobo-widget-popup-list-item-text');
        listItem.appendChild(textItem);

        const brandItem = document.createElement('p');
        brandItem.classList.add('garderobo-widget-popup-list-item-text-brand');
        brandItem.innerHTML = product.brand;
        textItem.appendChild(brandItem);

        const titleItem = document.createElement('h3');
        titleItem.classList.add('garderobo-widget-popup-list-item-text-title');
        titleItem.innerHTML = product.name;
        textItem.appendChild(titleItem);

        const bottomItems = document.createElement('div');
        bottomItems.classList.add('garderobo-widget-popup-list-item-text-bottom');
        textItem.appendChild(bottomItems);

        const bottomLeft = document.createElement('div');
        bottomItems.appendChild(bottomLeft);

        const pricesItem = document.createElement('div');
        pricesItem.classList.add('garderobo-widget-popup-list-item-text-prices');

        if (product.price && !product.old_price) {
            const priceItem = document.createElement('div');
            priceItem.classList.add('garderobo-widget-popup-list-item-text-price');
            priceItem.innerHTML = this.page.priceFormatter(product.price);
            pricesItem.appendChild(priceItem);
        } else if (product.price && product.old_price) {
            const discountItem = document.createElement('div');
            discountItem.classList.add('garderobo-widget-popup-list-item-text-discount');
            discountItem.innerHTML = this.page.priceFormatter(product.old_price);
            pricesItem.appendChild(discountItem);

            const priceNewItem = document.createElement('div');
            priceNewItem.classList.add('garderobo-widget-popup-list-item-text-new-price');
            priceNewItem.innerHTML = this.page.priceFormatter(product.price);
            pricesItem.appendChild(priceNewItem);
        }
        bottomLeft.appendChild(pricesItem);

        const isLookWithSizes = true; //look.block_with_sizes;

        // для демо-стенда
        if ((!product.sizes || !product.sizes.length) && this.page._widget.isVendorB44()) {
            product.sizes = [
                { yml_id: 1, name: 'XL', is_available: true },
                { yml_id: 2, name: 'L', is_available: true },
                { yml_id: 3, name: 'M', is_available: true },
                { yml_id: 4, name: 'S', is_available: true },
                { yml_id: 5, name: 'XS', is_available: true },
            ];
        }

        const defaultSelectValue = this.page.getText(Texts.SELECT_SIZE);
        if (isLookWithSizes && product.sizes && product.sizes.length) {
            const sizesSelect = document.createElement('select');
            sizesSelect.classList.add('garderobo-widget-sizes');
            const sizesOptionPlaceholder = document.createElement('option');
            sizesOptionPlaceholder.innerHTML = defaultSelectValue;
            sizesSelect.appendChild(sizesOptionPlaceholder);
            for (let j = 0; j < product.sizes.length; j++) {
                if (product.sizes[j].is_available) {
                    let sizeOption = document.createElement('option');
                    sizeOption.value = product.sizes[j].yml_id;
                    sizeOption.innerHTML = product.sizes[j].name;
                    sizesSelect.appendChild(sizeOption);
                }
            }
            sizesSelect.addEventListener('change', (event) => {
                const value = event.target.value;
                const btn = bottomItems.querySelector('.garderobo-widget-popup-list-item-text-cart-btn');
                const errBox = bottomItems.querySelector('.garderobo-widget-popup-list-item-text-error');
                if (btn) {
                    if (value === defaultSelectValue) {
                        btn.classList.add('garderobo-widget-popup-list-item-text-cart-btn--disabled');
                    } else {
                        btn.classList.remove('garderobo-widget-popup-list-item-text-cart-btn--disabled');
                        errBox.innerHTML = null;
                        let sizeValue = null;
                        for (let j = 0; j < product.sizes.length; j++) {
                            if (product.sizes[j].yml_id === value) {
                                sizeValue = product.sizes[j].name;
                                break;
                            }
                        }
                        this.ecommerce.sizeChangePopup(product, sizeValue);
                    }
                }
            });
            bottomLeft.appendChild(sizesSelect);
        }

        const cartBtn = document.createElement(isLookWithSizes ? 'button' : 'a');
        cartBtn.classList.add('garderobo-widget-popup-list-item-text-cart-btn');
        cartBtn.innerHTML = this.page.getText(Texts.TO_CART);
        if (!isLookWithSizes) {
            cartBtn.href = product.link;
            cartBtn.setAttribute('target', '_blank');
        } else {
            cartBtn.classList.add('garderobo-widget-popup-list-item-text-cart-btn--disabled');
            cartBtn.type = 'button';
            cartBtn.addEventListener('click', (e) => {
                const selectNode = cartBtn.parentNode.querySelector('.garderobo-widget-sizes');
                const errBox = cartBtn.parentNode.querySelector('.garderobo-widget-popup-list-item-text-error');
                if (!selectNode || !selectNode.value || selectNode.value === defaultSelectValue) {
                    errBox.innerHTML = this.page.getText(Texts.PLEASE_SELECT_SIZE);
                } else if (this.page._widget._callbacks.hasOwnProperty('addToCart')) {
                    this.page._widget.analytics.sendEventClick(product.wareId, 'looks', 'popup');
                    this.page._widget.analytics.sendEventAddToCartFromPopup(product_id, 'looks', this.page._widget.getPageSource(), product.price);
                    this.ecommerce.addToCartClickPopup(product, look, selectNode && selectNode.value);
                    this.page._widget._callbacks.addToCart.apply(null, [product.id, selectNode && selectNode.value]);
                    errBox.innerHTML = null;
                } else if (this.page._widget._callbacks.hasOwnProperty('addToCartEvent')) {
                    this.page._widget.analytics.sendEventClick(product.wareId, 'looks', 'popup');
                    this.page._widget.analytics.sendEventAddToCartFromPopup(product_id, 'looks', this.page._widget.getPageSource(), product.price);
                    this.page._widget._callbacks.addToCartEvent.apply(null, [selectNode && selectNode.value, product]);
                }
            });
        }
        bottomItems.appendChild(cartBtn);

        const errContainer = document.createElement('div');
        errContainer.classList.add('garderobo-widget-popup-list-item-text-error');
        bottomItems.appendChild(errContainer);

        // if (this.similars[productId]) {
        //     this.swapDraw(product, listItem, this.similars[productId], lookId, true);
        // }

        return listItem;
    }

    drawItemsForSwap(look) {
        const productIds = look.products.map((product) => product.wareId);

        this.page.fetchSimilarsForArray(null, productIds).then((data) => {
            this.similars = data;
            for (let wareId of productIds) {
                const product = look.products.find((product) => product.wareId === wareId);
                this.swapDraw(product, null, data[wareId], null);
            }
            setTimeout(() => this.removePreloader(), 100);
        });
    }

    swap(product, listItem, lookItemId, lookId) {
        //const swapItem = document.querySelector(`[data-product-item-id="${product.id}"] .garderobo-widget-popup-list-item-swap-container`);
        let alreadyLoaded = this.similars[product.id];

        if (alreadyLoaded) {
            const loadedProduct = alreadyLoaded.find((_product) => _product.id === product.id || _product.id === product.wareId);
            //console.log('____alreadyLoaded', loadedProduct);

            this.swapDraw(loadedProduct, listItem, this.similars[product.id], lookId);
        } else {
            this.page
                .fetchSimilars(lookItemId, product.wareId, { useCrop: 0 })
                .then((data) => {
                    if (data.products) {
                        const allProducts = [...data.products].map((_product) => {
                            _product['category-name'] = product['category-name'];
                            return _product;
                        });

                        // перемещаем начальный продукт в начало массива
                        const currentIndex = allProducts.findIndex((_product) => _product.id === product.wareId);
                        if (currentIndex !== 0 && currentIndex !== -1) {
                            const currentProduct = allProducts.splice(currentIndex, 1).pop();
                            allProducts.unshift(currentProduct);
                        }
                        this.similars[lookItemId] = allProducts;

                        for (let i = 0; i < allProducts.length; i++) {
                            let allProductsOther = [...allProducts];
                            let currentOtherProduct = allProductsOther[i];

                            // перемещаем продукт в начало массива
                            let currentIndex = allProductsOther.findIndex((_product) => _product.id === currentOtherProduct.id);
                            if (currentIndex !== 0 && currentIndex !== -1) {
                                const currentProduct = allProductsOther.splice(currentIndex, 1).pop();
                                allProductsOther.unshift(currentProduct);
                            }

                            this.similars[currentOtherProduct.id] = allProductsOther;
                        }
                        this.swapDraw(product, listItem, allProducts, lookId);
                    }
                })
                .catch(() => console.log('error'));
        }
    }

    swapDraw(product, _listItem, products, lookId, isHidden) {
        // console.log('___swap', product);
        const listItem = document.querySelector(`[data-product-item-id="${product.id}"]`).nextSibling;
        console.log(listItem);

        const swapContainer = document.createElement('div');
        swapContainer.classList.add('garderobo-widget-popup-list-item-swap-container');
        // if (isHidden) {
        //     swapContainer.classList.add('garderobo-widget-popup-list-item-swap-container--hidden');
        // }
        listItem.appendChild(swapContainer);

        const swapUl = document.createElement('ul');
        swapUl.classList.add('garderobo-widget-popup-list-item-swap-container-list');
        swapContainer.appendChild(swapUl);

        for (let i = 0; i < products.length; i++) {
            swapUl.appendChild(this.swapDrawItem(products[i], product));
        }
    }

    swapDrawItem(item, product) {
        let swapLi = document.createElement('li');
        swapLi.classList.add('garderobo-widget-popup-list-item-swap-container-item');

        // if (item.id === product.wareId || item.id === product.id) {
        //     swapLi.classList.add('garderobo-widget-popup-list-item-swap-container-item--selected');
        // }
        swapLi.setAttribute('data-similar-id', item.id);
        swapLi.addEventListener('click', (event) => {
            const _container = event.target.closest('.garderobo-widget-popup-swap-list-item').previousSibling;
            const productId = _container.getAttribute('data-product-item-id');
            let _product = this.items.find((product) => product.id == productId);
            if (!_product) {
                _product = this.similars[product.wareId].find((product) => product.id == productId);
            }
            this.replaceProduct(_product, item, null);
        }); // todo - remove listener
        //swapUl.appendChild(swapLi);

        let liContent = document.createElement('div');
        liContent.classList.add('garderobo-widget-popup-list-item-swap-item-content');
        if (item.id === product.id) {
            liContent.classList.add('garderobo-widget-popup-list-item-swap-item-content--selected');
        }
        swapLi.appendChild(liContent);

        let imgWrapper = document.createElement('div');
        imgWrapper.classList.add('garderobo-widget-popup-list-item-swap-item-img-wrapper');
        liContent.appendChild(imgWrapper);

        let img = document.createElement('img');
        img.classList.add('garderobo-widget-popup-list-item-swap-item-img');
        img.src = item.picture;
        imgWrapper.appendChild(img);

        const nameProductPopup = document.createElement('p');
        nameProductPopup.classList.add('garderobo-widget-popup-list-item-name');
        nameProductPopup.style.display = 'none';
        nameProductPopup.innerHTML = item.name;

        const brandProductPopup = document.createElement('p');
        brandProductPopup.classList.add('garderobo-widget-popup-list-item-brand');
        brandProductPopup.style.display = 'none';
        brandProductPopup.innerHTML = item.brand;

        if (item.price) {
            let price = document.createElement('span');
            price.classList.add('garderobo-widget-popup-list-item-swap-item-price');
            if (item.old_price) {
                price.classList.add('garderobo-widget-popup-list-item-swap-item-price--sale');
            }
            price.innerHTML = this.page.priceFormatter(item.price);
        }
        
        liContent.appendChild(nameProductPopup);
        liContent.appendChild(brandProductPopup);
        liContent.appendChild(price);
        
        return swapLi;
    }

    replaceProduct(product, replacer, lookId) {
        console.log(product, replacer);
        let currentLook;
        for (let i = 0; i < this.items.length; i++) {
            if (this.items[i].look_id === lookId) {
                currentLook = this.items[i];
                break;
            }
        }

        // todo - вроде уже не надо
        // const replacerEl = document.querySelector(`[data-similar-id="${replacer.id}"]`);
        // replacerEl.replaceWith(this.swapDrawItem(product, product));
        // console.log(replacerEl);

        const target = document.querySelector(`.garderobo-widget-popup-content [data-look="${lookId}"] [data-category-collage="${product['category-name']}"]`);
        if (target) {
            target.style['background-image'] = `url(${replacer.picture})`;
        }

        const swapContainer = document.querySelector(`[data-product-item-id="${product.id}"] .garderobo-widget-popup-list-item-swap-container`);

        // todo - fixxxxxxxx
        const oldProductItem =
            document.querySelector(`[data-product-item-id="${product.id}"`) || document.querySelector(`[data-product-item-id="${product.wareId}"`);
        //console.log(oldProductItem);
        if (oldProductItem) {
            const productIndex = Array.prototype.indexOf.call(oldProductItem.parentNode.children, oldProductItem);
            const container = document.querySelector('.garderobo-widget-popup-list-content');
            const newItem = this.drawRightItem(replacer, lookId, container, product.id, true, {}, productIndex);
            oldProductItem.replaceWith(newItem);

            //const newItemRendered = document.querySelector(`[data-product-item-id="${replacer.id}"]`); // todo - needed?
            //newItemRendered.appendChild(swapContainer); // tood - needed?
        }
        //swapContainer.remove();

        // for (let i = 0; i < this.items.length; i++) {
        //     if (this.items[i].look_id === lookId) {
        //         for (let j = 0; j < this.items[i].products.length; j++) {
        //             if (this.items[i].products[j].id === product.id) {
        //                 this.items[i].products[j] = replacer;
        //             }
        //         }
        //     }
        // }

        this.ecommerce.replaceProductPopup({ ...currentLook, products: [replacer] }, product, replacer);
    }

    getEcommerceLookData(look) {
        return {
            promoView: {
                promotions: look.products.map((product) => ({
                    id: look.look_id,
                    name: `Garderobo - product looks`,
                    creative: `${product.name} (${product.id})`,
                    //position: `${type} (${elementIndex} item)`,
                })),
            },
        };
    }

    getEcommerceLookPromoClick(look) {
        const mainProduct = look.products.find((product) => product.wareId === look.main_product_id);
        return {
            promoClick: {
                promotions: [
                    {
                        id: look.look_id,
                        name: `Garderobo - product looks`,
                        creative: `${mainProduct ? mainProduct.name : 'Главный продукт не определен'} (${look.main_product_id})`,
                    },
                ],
            },
        };
    }

    getEcommerceLookDataList(look) {
        return {
            currencyCode: 'RUR',
            impressions: look.products.map((product) => ({
                name: Utils.escapeHtml(product.name),
                id: this.getGtmIdParam(product) || product.id,
                price: product.price,
                brand: product.brand,
                category: product['category-name'],
                //variant: product.color,
                list: `Garderobo - product looks`,
                //position: 1,
            })),
        };
    }

    getEcommerceProductClick(product) {
        return {
            currencyCode: 'RUR',
            click: {
                actionField: {
                    list: 'Garderobo - product looks',
                },
                products: [
                    {
                        name: Utils.escapeHtml(product.name),
                        id: this.getGtmIdParam(product) || product.id,
                        price: product.price,
                        brand: product.brand,
                        category: product['category-name'],
                        list: `Garderobo - product looks`,
                    },
                ],
            },
        };
    }
}

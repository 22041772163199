import { Texts } from '../const';

export default class LooksSimplePopup {
    constructor(block, page) {
        this.block = block;
        this.items = block.products;
        this.sliderPos = 0;
        this.page = page;
        this.similars = {};
        this.ecommerce = page.ecommerce;
    }

    drawLooksPopup() {
        const underlay = document.createElement('div');
        underlay.classList.add('garderobo-widget-popup-container');

        const popup = document.createElement('div');
        popup.classList.add('garderobo-widget-popup');
        popup.classList.add('garderobo-looks-simple-popup');

        const closeBtn = document.createElement('button');
        closeBtn.type = 'button';
        closeBtn.classList.add('garderobo-widget-popup__btn-close');

        closeBtn.addEventListener('click', this.closePopup);
        popup.appendChild(closeBtn);

        const content = document.createElement('div');
        content.classList.add('garderobo-widget-popup-content');
        popup.appendChild(content);

        const listContainer = document.createElement('div');
        listContainer.classList.add('garderobo-widget-popup-list-container');
        content.appendChild(listContainer);

        const listHeader = document.createElement('div');
        listHeader.classList.add('garderobo-widget-popup-list-header');
        listHeader.innerHTML = this.page.getText(this.block.name);
        listContainer.appendChild(listHeader);

        const listContent = document.createElement('ul');
        listContent.classList.add('garderobo-widget-popup-list-content');
        listContainer.appendChild(listContent);

        underlay.appendChild(popup);

        // window.addEventListener('resize', () => {
        //     this.setPopupHeight();
        // });
        //this.setPopupHeight();

        return underlay;
    }

    openPopup() {
        const popupEl = document.querySelector('.garderobo-widget-popup-container');
        if (popupEl) {
            const closeHandler = this.closePopup;
            const onClosePopup = function (e) {
                e.stopImmediatePropagation();
                if (e.target !== this) return;

                popupEl.removeEventListener('click', onClosePopup);
                closeHandler();
            };
            popupEl.addEventListener('click', onClosePopup);
            popupEl.classList.add('garderobo-widget-popup-container--opened');

            this.drawRightItems(this.block);

            //this.sendGtm(this.getEcommerceLookDataList(_look), 'Product Impressions');    // todo - needed?

            //this.sendGtm(this.getEcommerceLookPromoClick(_look), 'Promotion Clicks');

            //this.setPopupHeight();
        }
    }

    closePopup() {
        const popupEl = document.querySelector('.garderobo-widget-popup-container');
        if (popupEl) {
            popupEl.classList.remove('garderobo-widget-popup-container--opened');
        }
    }

    drawCustomSelectSizeButtom(selectSizeControl, product, sizeSelectStandardControl) {
        let self = this;

        selectSizeControl.addEventListener('click', function() {
            const sizesPanel = document.createElement('div');
            sizesPanel.classList.add('garderobo-set-size-panel');

            const sizePanelHeader = document.createElement('h2');
            sizePanelHeader.innerHTML = self.page.getText(Texts.SELECT_SIZE);
            sizesPanel.appendChild(sizePanelHeader);

            const sizePanelCloseButton = document.createElement('button');
            sizePanelCloseButton.classList.add('garderobo-set-size-panel-close-button');
            sizesPanel.appendChild(sizePanelCloseButton);

            const sizePanelSizeButtons = document.createElement('div');
            sizePanelSizeButtons.classList.add('garderobo-set-size-panel-size-buttons');
            sizesPanel.appendChild(sizePanelSizeButtons);

            for (let i=0; i < product.sizes.length; i++) {
                let sizeButton = document.createElement('div');
                sizeButton.innerHTML = product.sizes[i].name;
                sizeButton.setAttribute('data-id', product.sizes[i].yml_id);
                sizePanelSizeButtons.appendChild(sizeButton);
                if (selectSizeControl.innerHTML == sizeButton.innerHTML) {
                    sizeButton.classList.add('garderobo-set-size-panel-size-buttons-active');
                }

                sizeButton.addEventListener('click', function() {
                    sizeSelectStandardControl.value = this.getAttribute('data-id');
                    let event = new Event('change');
                    sizeSelectStandardControl.dispatchEvent(event);
                    sizesPanel.classList.remove('garderobo-set-size-panel-active');

                    selectSizeControl.innerHTML = this.innerHTML;
                });
            }
            document.querySelector('.garderobo-widget-popup-container').appendChild(sizesPanel);
            setTimeout(function() {
                sizesPanel.classList.add('garderobo-set-size-panel-active');
                document.querySelector('.garderobo-widget-popup').addEventListener('click', function(event) {
                    if (event.target && !event.target.classList.contains('garderobo-set-size-panel-active')) {
                        sizesPanel.classList.remove('garderobo-set-size-panel-active');
                    }
                });

            }, 100);

            sizePanelCloseButton.addEventListener('click', function() {
                sizesPanel.classList.remove('garderobo-set-size-panel-active');
            });
        });
    }

    drawRightItems(look) {
        const container = document.querySelector('.garderobo-widget-popup-list-content');
        container.innerHTML = null;

        for (let i = 0; i < look.products.length; i++) {
            this.drawRightItem(look.products[i], look.look_id, container, look.items_ids[i], false, look, i);
        }
    }

    setLikedState(product, likeButton) {
        let self = this;
        if (this.page._widget.favoritesProductsIds) {
            if (this.page._widget.favoritesProductsIds.includes(product.group_id + '') || this.page._widget.favoritesProductsIds.includes(product.id + '')) {
                console.log('LIKED');
                likeButton.classList.add('garderobo-like-button-liked-state');
            }
        }

        likeButton.addEventListener('click', (event) => {
            event.stopPropagation();

            let state = true;
            if (event.target.classList.contains('garderobo-like-button-liked-state')) {
                state = false;
                event.target.classList.remove('garderobo-like-button-liked-state');
                let index = this.page._widget.favoritesProductsIds.indexOf(product.group_id + '');
                if (index !== -1) {
                    this.page._widget.favoritesProductsIds.splice(index, 1);
                }
            } else {
                event.target.classList.add('garderobo-like-button-liked-state');
                if (!this.page._widget.favoritesProductsIds.includes(product.group_id + '')) {
                    this.page._widget.favoritesProductsIds.push(product.group_id + '');
                }
            }
            self.page._widget._callbacks.addToFavorites.apply(null, [product.group_id, state]);
        });
    }

    drawRightItem(product, lookId, container, productId, dontRender, look, productIndex) {
        // console.log('------', product);
        let self = this;

        const listItem = document.createElement('li');
        listItem.classList.add('garderobo-widget-popup-list-item');
        listItem.setAttribute('data-product-item-id', product.id);

        this.page.setProductAttributes(listItem, product, productIndex);

        if (!dontRender) {
            container.appendChild(listItem);
        }

        const picItem = document.createElement('div');
        picItem.classList.add('garderobo-widget-popup-list-item-pic');
        listItem.appendChild(picItem);

        const imgWrapper = document.createElement('div');
        imgWrapper.classList.add('garderobo-widget-popup-list-item-img-wrapper');
        picItem.appendChild(imgWrapper);

        let imgUrl;
        if (product.link) {
            imgUrl = document.createElement('a');

            // UTM stuff
            let link = product.link;
            let template = this.page.getWidgetParam('utm_template');
            if (template) {
                link +=
                    (link.indexOf('?') === -1 ? '?' : '&') +
                    template.replace('{block_type}', 'looks').replace('{page_type}', this.page._widget.getPageSource).replace('{yml_id}', product.yml_id);
            }

            imgUrl.href = link;
            imgUrl.addEventListener('click', () => {
                this.ecommerce.productClickPopup(product, look);
                let wareId = product.wareId ? product.wareId : product.id;
                self.page._widget.analytics.sendEventWidgetClick(wareId, 'looks', self.page._widget.getPageSource());
            });
            imgWrapper.appendChild(imgUrl);
        }

        const imgItem = document.createElement('img');
        imgItem.classList.add('garderobo-widget-popup-list-item-img-v2');
        imgItem.src = product.picture;
        if (imgUrl) {
            imgUrl.appendChild(imgItem);
        } else {
            imgWrapper.appendChild(imgItem);
        }

        const swapItem = document.createElement('span');
        swapItem.classList.add('garderobo-widget-popup-list-item-swap');
        swapItem.innerHTML = this.page.getText(Texts.CHANGE);
        swapItem.addEventListener('click', () => this.swap(product, listItem, productId, lookId));
        picItem.appendChild(swapItem);

        // todo - outfit-swap-container

        const textItem = document.createElement('div');
        textItem.classList.add('garderobo-widget-popup-list-item-text');
        listItem.appendChild(textItem);

        const likeButton = document.createElement('div');
        likeButton.classList.add('garderobo-widget-popup-list-item-like-button');
        textItem.appendChild(likeButton);
        this.setLikedState(product, likeButton);

        const brandItem = document.createElement('p');
        brandItem.classList.add('garderobo-widget-popup-list-item-text-brand');
        brandItem.innerHTML = product.brand;
        textItem.appendChild(brandItem);

        const titleItem = document.createElement('h3');
        titleItem.classList.add('garderobo-widget-popup-list-item-text-title');

        const titleHref = document.createElement('a');
        titleHref.innerHTML = product.name;
        titleHref.href = product.link;
        titleItem.appendChild(titleHref);

        textItem.appendChild(titleItem);

        const bottomItems = document.createElement('div');
        bottomItems.classList.add('garderobo-widget-popup-list-item-text-bottom');
        textItem.appendChild(bottomItems);

        const bottomLeft = document.createElement('div');
        bottomItems.appendChild(bottomLeft);

        const pricesItem = document.createElement('div');
        pricesItem.classList.add('garderobo-widget-popup-list-item-text-prices');

        if (product.price && !product.old_price) {
            const priceItem = document.createElement('div');
            priceItem.classList.add('garderobo-widget-popup-list-item-text-price');
            priceItem.innerHTML = this.page.priceFormatter(product.price);
            pricesItem.appendChild(priceItem);
        } else if (product.price && product.old_price) {
            const discountItem = document.createElement('div');
            discountItem.classList.add('garderobo-widget-popup-list-item-text-discount');
            discountItem.innerHTML = this.page.priceFormatter(product.old_price);
            pricesItem.appendChild(discountItem);

            const priceNewItem = document.createElement('div');
            priceNewItem.classList.add('garderobo-widget-popup-list-item-text-new-price');
            priceNewItem.innerHTML = this.page.priceFormatter(product.price);
            pricesItem.appendChild(priceNewItem);
        }
        bottomLeft.appendChild(pricesItem);

        const isLookWithSizes = look.block_with_sizes;

        const defaultSelectValue = this.page.getText(Texts.SELECT_SIZE);
        const sizesSelect = document.createElement('select');
        if (isLookWithSizes) {
            sizesSelect.classList.add('garderobo-widget-sizes');
            const sizesOptionPlaceholder = document.createElement('option');
            sizesOptionPlaceholder.innerHTML = defaultSelectValue;
            sizesSelect.appendChild(sizesOptionPlaceholder);
            if (product.sizes && product.sizes.length) {
                for (let j = 0; j < product.sizes.length; j++) {
                    if (product.sizes[j].is_available) {
                        let sizeOption = document.createElement('option');
                        sizeOption.value = product.sizes[j].yml_id;
                        sizeOption.innerHTML = product.sizes[j].name;
                        sizesSelect.appendChild(sizeOption);
                    }
                }
            } else {
                let sizeOption = document.createElement('option');
                sizeOption.value = product.yml_id;
                sizeOption.innerHTML = this.page.getText(Texts.ONE_SIZE);
                sizesSelect.appendChild(sizeOption);
            }
            sizesSelect.addEventListener('change', (event) => {
                const value = event.target.value;
                const btn = bottomItems.querySelector('.garderobo-widget-popup-list-item-text-cart-btn');
                const errBox = bottomItems.querySelector('.garderobo-widget-popup-list-item-text-error');
                if (btn) {
                    if (value === defaultSelectValue) {
                        btn.classList.add('garderobo-widget-popup-list-item-text-cart-btn--disabled');
                    } else {
                        btn.classList.remove('garderobo-widget-popup-list-item-text-cart-btn--disabled');
                        errBox.innerHTML = null;
                        let sizeValue = null;
                        for (let j = 0; j < product.sizes.length; j++) {
                            if (product.sizes[j].yml_id === value) {
                                sizeValue = product.sizes[j].name;
                                if (self.page._widget.cartProductsIds) {
                                    if (self.page._widget.cartProductsIds.includes(product.sizes[j].yml_id + '')) {
                                        let btnAlreadyInCartCaption = 'Перейти в корзину';
                                        btn.classList.add('garderobo-widget-popup-list-item-text-cart-btn-link-state');
                                        if (btn.innerHTML != btnAlreadyInCartCaption)
                                            btn.setAttribute('data-text', btn.innerHTML);
                                        btn.innerHTML = btnAlreadyInCartCaption;
                                        if (self.page._widget.cartLink)
                                            btn.setAttribute('data-link', self.page._widget.cartLink);
                                    } else {
                                        if (btn.getAttribute('data-text'))
                                            btn.innerHTML = btn.getAttribute('data-text');
                                        btn.removeAttribute('data-link');
                                        btn.classList.remove('garderobo-widget-popup-list-item-text-cart-btn-link-state');
                                    }
                                }

                                break;
                            }
                        }

                        this.ecommerce.sizeChangePopup(product, sizeValue);
                    }
                }
            });
            bottomLeft.appendChild(sizesSelect);
        }

        const cartBtn = document.createElement(isLookWithSizes ? 'button' : 'a');
        cartBtn.classList.add('garderobo-widget-popup-list-item-text-cart-btn');
        cartBtn.innerHTML = this.page.getText(Texts.TO_CART);
        if (!isLookWithSizes) {
            cartBtn.href = product.link;
            cartBtn.setAttribute('target', '_blank');
        } else {
            cartBtn.classList.add('garderobo-widget-popup-list-item-text-cart-btn--disabled');
            cartBtn.type = 'button';
            cartBtn.addEventListener('click', (e) => {
                if (cartBtn.getAttribute('data-link')) {
                    document.location.href = cartBtn.getAttribute('data-link');
                    return;
                }

                const selectNode = cartBtn.parentNode.querySelector('.garderobo-widget-sizes');
                let offersPropsByYmlId;

                if (product.offers_props_by_yml_id && selectNode.value) {
                    const key = Object.keys(product.offers_props_by_yml_id[selectNode.value])[0];
                    offersPropsByYmlId = product.offers_props_by_yml_id[selectNode.value][key];
                } else if (selectNode && selectNode.value) {
                    offersPropsByYmlId = selectNode.value;
                }

                let product_id;
                if (product.wareId)
                    product_id = product.wareId;
                else
                    product_id = product.id;

                const errBox = cartBtn.parentNode.querySelector('.garderobo-widget-popup-list-item-text-error');
                if (!selectNode || !selectNode.value || selectNode.value === defaultSelectValue) {
                    errBox.innerHTML = this.page.getText(Texts.PLEASE_SELECT_SIZE);
                } else if (this.page._widget._callbacks.hasOwnProperty('addToCart')) {
                    this.page._widget.analytics.sendEventClick(product.wareId, 'looks', 'popup');
                    this.page._widget.analytics.sendEventAddToCartFromPopup(product_id, 'looks', this.page._widget.getPageSource(), product.price);
                    this.ecommerce.addToCartClickPopup(product, look, selectNode && selectNode.value);
                    this.page._widget._callbacks.addToCart.apply(null, [product.id, offersPropsByYmlId]);
                    errBox.innerHTML = null;
                }  else if(this.page._widget._callbacks.hasOwnProperty('addToCartEvent')) {
                    this.page._widget.analytics.sendEventClick(product.wareId, 'looks', 'popup');
                    this.page._widget.analytics.sendEventAddToCartFromPopup(product_id, 'looks', this.page._widget.getPageSource(), product.price);
                    this.ecommerce.addToCartClickPopup(product, look, offersPropsByYmlId);
                    this.page._widget._callbacks.addToCartEvent.apply(null, [offersPropsByYmlId, product]);

                    if (self.page._widget.cartProductsIds) {
                        let sizesSelect = cartBtn.parentNode.parentNode.querySelector('.garderobo-widget-sizes');
                        if (sizesSelect)
                            sizesSelect.dispatchEvent(new Event('change'));
                    }
                }
            });
        }

        if (this.page.getWidgetParam('custom_select_size_popup')) {
            sizesSelect.style.display = 'None';

            const sizesSelectButton = document.createElement('div');
            sizesSelectButton.classList.add('garderobo-widget-sizes-custom-button');
            sizesSelectButton.innerHTML = defaultSelectValue;

            bottomItems.appendChild(sizesSelectButton);
            self.drawCustomSelectSizeButtom(sizesSelectButton, product, sizesSelect);
        }

        bottomItems.appendChild(cartBtn);

        const errContainer = document.createElement('div');
        errContainer.classList.add('garderobo-widget-popup-list-item-text-error');
        bottomItems.appendChild(errContainer);

        if (this.similars[productId]) {
            this.swapDraw(product, listItem, this.similars[productId], lookId, true);
        }

        if (product.sizes && product.sizes.length == 1) {
            sizesSelect.value = product.sizes[0].yml_id;
            sizesSelect.dispatchEvent(new Event('change'));
            sizesSelect.disabled = true;
        }

        return listItem;
    }

    swap(product, listItem, lookItemId, lookId) {
        //const swapItem = document.querySelector(`[data-product-item-id="${product.id}"] .garderobo-widget-popup-list-item-swap-container`);
        let alreadyLoaded = this.similars[product.id];

        if (alreadyLoaded) {
            const loadedProduct = alreadyLoaded.find((_product) => _product.id === product.id || _product.id === product.wareId);
            //console.log('____alreadyLoaded', loadedProduct);

            this.swapDraw(loadedProduct, listItem, this.similars[product.id], lookId);
        } else {
            this.page
                .fetchSimilars(lookItemId, product.wareId, { useCrop: 0 })
                .then((data) => {
                    if (data.products) {
                        const allProducts = [...data.products].map((_product) => {
                            _product['category-name'] = product['category-name'];
                            return _product;
                        });

                        // перемещаем начальный продукт в начало массива
                        const currentIndex = allProducts.findIndex((_product) => _product.id === product.wareId);
                        if (currentIndex !== 0 && currentIndex !== -1) {
                            const currentProduct = allProducts.splice(currentIndex, 1).pop();
                            allProducts.unshift(currentProduct);
                        }
                        this.similars[lookItemId] = allProducts;

                        for (let i = 0; i < allProducts.length; i++) {
                            let allProductsOther = [...allProducts];
                            let currentOtherProduct = allProductsOther[i];

                            // перемещаем продукт в начало массива
                            let currentIndex = allProductsOther.findIndex((_product) => _product.id === currentOtherProduct.id);
                            if (currentIndex !== 0 && currentIndex !== -1) {
                                const currentProduct = allProductsOther.splice(currentIndex, 1).pop();
                                allProductsOther.unshift(currentProduct);
                            }

                            this.similars[currentOtherProduct.id] = allProductsOther;
                        }
                        this.swapDraw(product, listItem, allProducts, lookId);
                    }
                })
                .catch(() => console.log('error'));
        }
    }

    swapDraw(product, listItem, products, lookId, isHidden) {
        // console.log('___swap', product);

        const swapContainer = document.createElement('div');
        swapContainer.classList.add('garderobo-widget-popup-list-item-swap-container');
        if (isHidden) {
            swapContainer.classList.add('garderobo-widget-popup-list-item-swap-container--hidden');
        }
        listItem.appendChild(swapContainer);

        const swapUl = document.createElement('ul');
        swapUl.classList.add('garderobo-widget-popup-list-item-swap-container-list');
        swapContainer.appendChild(swapUl);

        for (let i = 0; i < products.length; i++) {
            let item = products[i];
            let swapLi = document.createElement('li');
            swapLi.classList.add('garderobo-widget-popup-list-item-swap-container-item');

            if (item.id === product.wareId || item.id === product.id) {
                swapLi.classList.add('garderobo-widget-popup-list-item-swap-container-item--selected');

                let swapCloseBtn = document.createElement('button');
                swapCloseBtn.setAttribute('type', 'button');
                swapCloseBtn.classList.add('garderobo-widget-popup__btn-close');
                swapLi.appendChild(swapCloseBtn);
            }
            swapLi.setAttribute('data-similar-id', item.id);
            swapLi.addEventListener('click', () => this.replaceProduct(product, item, lookId)); // todo - remove listener
            swapUl.appendChild(swapLi);

            let liContent = document.createElement('div');
            liContent.classList.add('garderobo-widget-popup-list-item-swap-item-content');
            if (item.id === product.id) {
                liContent.classList.add('garderobo-widget-popup-list-item-swap-item-content--selected');
            }
            swapLi.appendChild(liContent);

            if (lookId) {
                const likeButton = document.createElement('div');
                likeButton.classList.add('garderobo-widget-popup-list-swap-item-like-button');
                liContent.appendChild(likeButton);
                this.setLikedState(item, likeButton);
            }

            let imgWrapper = document.createElement('div');
            imgWrapper.classList.add('garderobo-widget-popup-list-item-swap-item-img-wrapper');
            liContent.appendChild(imgWrapper);

            let img = document.createElement('img');
            img.classList.add('garderobo-widget-popup-list-item-swap-item-img');
            img.src = item.picture;
            imgWrapper.appendChild(img);

            const nameProductPopup = document.createElement('p');
            nameProductPopup.classList.add('garderobo-widget-popup-list-item-name');
            nameProductPopup.style.display = 'none';
            nameProductPopup.innerHTML = item.name;

            let price = document.createElement('span');
            price.classList.add('garderobo-widget-popup-list-item-swap-item-price');
            if (item.price) {
                if (item.old_price) {
                    price.classList.add('garderobo-widget-popup-list-item-swap-item-price--sale');
                }
                price.innerHTML = this.page.priceFormatter(item.price);
            }

            liContent.appendChild(nameProductPopup);
            liContent.appendChild(price);
        }
    }

    replaceProduct(product, replacer, lookId) {
        let currentLook = this.block;

        const target = document.querySelector(`.garderobo-widget-popup-content [data-look="${lookId}"] [data-category-collage="${product['category-name']}"]`);
        if (target) {
            target.style['background-image'] = `url(${replacer.picture})`;
        }

        const swapContainer = document.querySelector(`[data-product-item-id="${product.id}"] .garderobo-widget-popup-list-item-swap-container`);

        const oldProductItem = swapContainer.parentNode;
        if (oldProductItem) {
            const productIndex = Array.prototype.indexOf.call(oldProductItem.parentNode.children, oldProductItem);
            const container = document.querySelector('.garderobo-widget-popup-list-content');
            const newItem = this.drawRightItem(replacer, lookId, container, product.id, true, currentLook, productIndex);
            oldProductItem.replaceWith(newItem);

            const newItemRendered = document.querySelector(`[data-product-item-id="${replacer.id}"]`); // todo - needed?
            newItemRendered.appendChild(swapContainer); // tood - needed?
        }
        swapContainer.remove();

        for (let i = 0; i < this.items.length; i++) {
            if (this.items[i].look_id === lookId) {
                for (let j = 0; j < this.items[i].products.length; j++) {
                    if (this.items[i].products[j].id === product.id) {
                        this.items[i].products[j] = replacer;
                    }
                }
            }
        }

        this.ecommerce.replaceProductPopup({ ...currentLook, products: [replacer] }, product, replacer);
    }
}

import Const from '../const';
import HandledPromise from './handled-promise';

let Slider = (itemsContainer, sliderContainer, sliderType = null, isInfinite = true, langCode) => {
    let self = {};

    try {
        self.items = [];
        self.slidePosition = 0;
        self.slideMaxCount = 0;

        self.itemsContainer = itemsContainer;
        self.sliderContainer = sliderContainer;

        self.leftControl = document.createElement('div');
        self.leftControl.classList.add(Const.widgetLeftButtonClass);
        self.leftControl.classList.add(Const.widgetHiddenClass);

        self.rightControl = document.createElement('div');
        self.rightControl.classList.add(Const.widgetRightButtonClass);
        self.rightControl.classList.add(Const.widgetHiddenClass);

        self.containerWidth = 0;
        self.itemWidth = 0;
        self.sliderCount = 0;
        self.infinite = isInfinite;
        self.preventSliding = false;
        self.isTouching = false;
        self.touchOrigin = 0;
        self.sliderType = sliderType;
        self.scrollCount = 1;
        self.slideIndex = 0;

        self.isReverse = langCode === 'ar';

        const widgetContainerEl = itemsContainer.closest('.garderobo-widget-feed');
        if (widgetContainerEl) {
            const counterEl = widgetContainerEl.querySelector('.' + Const.widgetFeedItemsCounterClass);
            if (counterEl) {
                self.counterEl = counterEl;
                self.counterBtnPrev = counterEl.querySelector('.' + Const.widgetFeedItemsCounterBtnPrevClass);
                self.counterTextCurr = counterEl.querySelector('.' + Const.widgetFeedItemsCounterTextCurrentClass);
                self.counterTextTotal = counterEl.querySelector('.' + Const.widgetFeedItemsCounterTextTotalClass);
                self.counterBtnNext = counterEl.querySelector('.' + Const.widgetFeedItemsCounterBtnNextClass);
            }
        }
    } catch (e) {
        new HandledPromise((_, reject) => reject(e));
    }

    self.getCurrentItems = () => {
        return self.items.slice(self.slidePosition, self.slidePosition + self.sliderCount);
    };

    self.slideCallback = () => { };

    self.registerSlideCallback = (callback) => {
        self.slideCallback = callback;
    };

    self.
        setSliderPosition = (position) => {
            itemsContainer.style[!self.isReverse ? 'left' : 'right'] = -1 * position * self.itemWidth + 'px';
        };

    self.slideLookHandler = (_direction) => {
        return () => {
            let buttonClass = '.garderobo-multiple-btn-next';
            if (_direction == -1)
                 buttonClass = '.garderobo-multiple-btn-prev';

            const buttonElement = document.querySelector(buttonClass);

            if (buttonElement) {
                // Создаем и инициируем событие click
                const clickEvent = new MouseEvent('click', {
                    bubbles: true,
                    cancelable: true,
                    view: window
                });
                buttonElement.dispatchEvent(clickEvent);
            }
        }
    };

    self.getFeedItemByIndex = (index) => {
        let counter = 0;
        for (let i = 0; i < self.itemsContainer.childNodes.length; i++) {
            let container = self.itemsContainer.childNodes[i];
            if (!container.classList.contains('duplicate')) {
                if (counter == index)
                    return container.childNodes.item(0);
                else
                    counter += 1;
            }
        }
    }

    self.slideHandler = (_direction) => {
        return () => {
            if(isInfinite) {
                let sliderItemsCount = self.items.length;
                if (sliderItemsCount == itemsContainer.children.length) {
                    if ((sliderItemsCount >= 2) && (sliderItemsCount <= 4)) {
                        let duplicateItems = 4;
                        if (sliderItemsCount == 3)
                            duplicateItems = 6;

                        if (duplicateItems == 4)
                            self.slideMaxCount = 8;
                        else
                            self.slideMaxCount = 6;

                        for (let i = 0; i < duplicateItems; i++) {
                            let lookId = itemsContainer.childNodes.item(i % sliderItemsCount).getAttribute('data-look');
                            if (lookId)
                                itemsContainer.insertAdjacentHTML('beforeend', `<div class="garderobo-widget-feed-item duplicate" data-index="${i % sliderItemsCount}" data-look-duplicate="${lookId}">${itemsContainer.childNodes.item(i % sliderItemsCount).innerHTML}</div>`);
                            else
                                itemsContainer.insertAdjacentHTML('beforeend', `<div class="garderobo-widget-feed-item duplicate" data-index="${i % sliderItemsCount}">${itemsContainer.childNodes.item(i % sliderItemsCount).innerHTML}</div>`);

                            const newItem = itemsContainer.lastChild;
                            newItem.addEventListener('click', function() {
                                self.getFeedItemByIndex(this.getAttribute('data-index')).dispatchEvent(new Event('click'));
                            });
                        }
                    }
                }
            }


            new HandledPromise((_, reject) => {
                const direction = !self.isReverse ? _direction : -1 * _direction;
                // prevent sliding until animation finishes if transition duration is present
                if (self.preventSliding) return;

                let transitionDuration = window.getComputedStyle(itemsContainer).getPropertyValue('transition-duration');

                if (parseFloat(transitionDuration)) {
                    self.preventSliding = true;
                    setTimeout(() => {
                        self.preventSliding = false;
                    }, parseFloat(transitionDuration) * 1000);
                }

                self.leftControl.classList.remove(Const.widgetMutedClass);
                self.rightControl.classList.remove(Const.widgetMutedClass);
                if (self.counterBtnPrev) {
                    self.counterBtnPrev.removeAttribute('disabled');
                }
                if (self.counterBtnNext) {
                    self.counterBtnNext.removeAttribute('disabled');
                }

                if (self.infinite) {
                    itemsContainer.style.transitionDuration = '0s';

                    //console.log(self.slidePosition, direction, self.sliderCount, self.slideMaxCount);
                    //if (self.slidePosition + direction < 0) {

                    if (self.slidePosition + direction * self.scrollCount <= 0) {
                        const diffCount = (self.slidePosition + direction * self.scrollCount) * -1;
                        for (let i = 0; i < diffCount; i++) {
                            //console.log('insertBefore', self.slidePosition);
                            itemsContainer.insertBefore(
                                itemsContainer.childNodes.item(itemsContainer.childNodes.length - 1),
                                itemsContainer.childNodes.item(0)
                            );
                            self.items.unshift(self.items.pop());
                        }
                        self.slidePosition = self.scrollCount; //1;
                        self.setSliderPosition(self.slidePosition);
                        //} else if (self.slidePosition + direction * self.sliderCount === self.slideMaxCount) {
                    } else if (self.slidePosition + (self.sliderType === 'page' ? self.sliderCount : 0) + direction * self.sliderCount >= self.slideMaxCount) {
                        let diffCount =
                            self.slidePosition + (self.sliderType === 'page' ? self.sliderCount : 0) + direction * self.sliderCount - self.slideMaxCount;
                        diffCount = diffCount === 0 ? 1 : diffCount;

                        for (let i = 0; i < diffCount; i++) {
                            itemsContainer.appendChild(itemsContainer.childNodes.item(0));
                            self.items.push(self.items.shift());
                            self.slidePosition--;
                        }
                        self.setSliderPosition(self.slidePosition);
                    } else {
                        if (self.items.length > 4 && self.slidePosition >= 1) {
                            itemsContainer.appendChild(itemsContainer.childNodes.item(0));
                            self.slidePosition--;
                            self.setSliderPosition(self.slidePosition);
                        }
                    }
                }

                setTimeout(() => {
                    try {
                        if (self.infinite) itemsContainer.style.transitionDuration = transitionDuration;

                        if (self.slidePosition + direction >= 0 && self.slidePosition + direction * self.scrollCount < self.slideMaxCount) {
                            let newPos = self.slidePosition + direction * self.scrollCount;
                            if (newPos + direction * self.scrollCount > self.slideMaxCount) {
                                newPos = self.slideMaxCount - direction * self.scrollCount;
                            } else if (newPos < 0) {
                                newPos = 0;
                            }
                            self.slidePosition = newPos;
                            self.setSliderPosition(newPos);
                        }

                        if (!self.infinite) {
                            if (self.slidePosition === 0) {
                                self.leftControl.classList.add(Const.widgetMutedClass);
                                if (self.counterBtnPrev) {
                                    self.counterBtnPrev.setAttribute('disabled', true);
                                }
                            } else if (self.slidePosition + self.sliderCount === self.slideMaxCount) {
                                self.rightControl.classList.add(Const.widgetMutedClass);
                                if (self.counterBtnNext) {
                                    self.counterBtnNext.setAttribute('disabled', true);
                                }
                            }
                        }

                        self.slideIndex += direction;
                        if (self.slideIndex === self.items.length) {
                            self.slideIndex = 0;
                        } else if (self.slideIndex === -1) {
                            self.slideIndex = self.items.length - 1;
                        }

                        if (self.counterTextCurr) {
                            self.counterTextCurr.innerHTML = self.slideIndex + 1;
                        }

                        self.slideCallback(self.getCurrentItems(), direction);
                        console.log('8');

                    } catch (e) {
                        console.log('9');
                        reject(e);
                    }
                }, 50);
            });
        };
    };

    self.slideLeft = self.slideHandler(-1);
    self.slideRight = self.slideHandler(1);

    self.slideLookLeft = self.slideLookHandler(-1);
    self.slideLookRight = self.slideLookHandler(1);

    self.swipeHandler = (e) => {
        switch (e.type) {
            case 'touchstart':
                if (!self.isTouching && e.touches && e.touches.length == 1) {
                    self.isTouching = true;
                    self.touchOrigin = e.touches[0].clientX;
                }
                break;
            case 'touchmove':
                if (!self.isTouching) break;

                let touchTransition = self.touchOrigin - e.touches[0].clientX;

                if (Math.abs(touchTransition) > 50) {
                    if (touchTransition > 0 && self.slideMaxCount - self.slideIndex !== 1) {
                        self.slideRight();
                        document.dispatchEvent(new CustomEvent('swipeRight'));
                    } else if (touchTransition < 0 && self.slideIndex > 0) {
                        self.slideLeft();
                        document.dispatchEvent(new CustomEvent('swipeLeft'));
                    }
                    self.isTouching = false;
                    self.touchOrigin = 0;
                }
                break;
            case 'touchend':
                self.isTouching = false;
                self.touchOrigin = 0;
                break;
        }
    };

    self.turnOff = () => {
        self.setSliderPosition(0);
        self.leftControl.removeEventListener('click', self.slideLeft);
        self.rightControl.removeEventListener('click', self.slideRight);
        self.itemsContainer.removeEventListener('touchstart', self.swipeHandler, false);
        self.itemsContainer.removeEventListener('touchmove', self.swipeHandler, false);
        self.itemsContainer.removeEventListener('touchend', self.swipeHandler, false);
        if (self.counterTextCurr) {
            self.counterBtnPrev.removeEventListener('click', self.slideLeft);
            self.counterBtnNext.removeEventListener('click', self.slideRight);
        }
    };

    self.turnOn = () => {
        self.setSliderPosition(0);
        self.slidePosition = 0;

        if (self.sliderType == 'look') {
            if (self.counterTextCurr) {
                self.counterBtnPrev.addEventListener('click', self.slideLookLeft);
                self.counterBtnNext.addEventListener('click', self.slideLookRight);
            }
        } else {
            self.leftControl.addEventListener('click', self.slideLeft);
            self.rightControl.addEventListener('click', self.slideRight);
            self.itemsContainer.addEventListener('touchstart', self.swipeHandler, false);
            self.itemsContainer.addEventListener('touchmove', self.swipeHandler, false);
            self.itemsContainer.addEventListener('touchend', self.swipeHandler, false);
            if (self.counterTextCurr) {
                self.counterBtnPrev.addEventListener('click', self.slideLeft);
                self.counterBtnNext.addEventListener('click', self.slideRight);
            }
        }
    };

    self.turnOn();

    self.reinit = () => {
        const oldDuration = window.getComputedStyle(itemsContainer).getPropertyValue('transition-duration');
        self.itemsContainer.style.transitionDuration = '0s';


        if (self.items) {
            self.init(self.items);

            setTimeout(() => {
                self.itemsContainer.style.transitionDuration = oldDuration;
            });
        }
    };

    self.init = (items) => {
        new HandledPromise(() => {
            self.items = items;
            self.slideMaxCount = items.length;
            const element = itemsContainer.querySelector('.' + Const.widgetItemClass);

            self.itemWidth = element.offsetWidth;

            const marginRight = getComputedStyle(element).marginRight;
            const marginLeft = getComputedStyle(element).marginRight;
            const replacedMarginRight = +marginRight.replace('px', '');
            const replacedMarginLeft = +marginLeft.replace('px', '');
            if (replacedMarginRight > 0) {
                self.itemWidth = self.itemWidth + replacedMarginRight;
            }
            if (replacedMarginLeft > 0) {
                self.itemWidth = self.itemWidth + replacedMarginLeft;
            }

            self.sliderCount = Math.round(self.sliderContainer.clientWidth / self.itemWidth);

            if (!self.infinite) {
                self.leftControl.classList.add(Const.widgetMutedClass);

                if (self.counterBtnPrev) {
                    self.counterBtnPrev.setAttribute('disabled', true);
                }

                if (self.sliderCount >= self.slideMaxCount) {
                    self.rightControl.classList.add(Const.widgetMutedClass);

                    if (self.counterBtnNext) {
                        self.counterBtnNext.setAttribute('disabled', true);
                    }
                }
            }

            if (self.sliderCount < self.slideMaxCount || self.sliderCount === self.slideMaxCount) {
                self.leftControl.classList.remove(Const.widgetHiddenClass);
                self.rightControl.classList.remove(Const.widgetHiddenClass);
                if (self.counterEl)
                    self.counterEl.classList.remove(Const.widgetHiddenClass);
            } else {
                if ((self.sliderType != 'look') && (self.counterEl))
                    self.counterEl.classList.add(Const.widgetHiddenClass);
            }

            if (self.sliderType === 'page') {
                self.scrollCount = self.sliderCount;
            }

            if (self.counterTextCurr && items && items.length) {
                self.counterTextCurr.innerHTML = 1;
                if ((self.sliderType == 'page') && (self.sliderCount))
                    self.counterTextTotal.innerHTML = Math.ceil(items.length / self.sliderCount);
                else
                    self.counterTextTotal.innerHTML = items.length;
            }

            if (self.counterEl) {
                if (self.items.length === 4) {
                    self.counterEl.classList.add("showItems4");
                } else {
                    self.counterEl.classList.remove("showItems4");
                }

                if (self.items.length === 3) {
                    self.counterEl.classList.add("showItems3");
                } else {
                    self.counterEl.classList.remove("showItems3");
                }

                if (self.items.length === 2) {
                    self.counterEl.classList.add("showItems2");
                } else {
                    self.counterEl.classList.remove("showItems2");
                }
            }
        });
    };

    return self;
};

export default Slider;

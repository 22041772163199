import {Texts} from "../const";

class CollageV3 {
    constructor(products, container, page, customCode) {
        this.page = page;
        this.container = container;
        this.products = products;

        this.productContainer = document.createElement('div');
        this.productContainer.classList.add('garderobo-widget-look-container');

        this.drawCollage(products, customCode);

        container.appendChild(this.productContainer);
        return this.productContainer;
    }

    getRootTypes(products) {
        let result = [];
        let currentIndexes = {};
        let currentMainIndexes = {};
        let topTypes = ['layer-1', 'layer-2', 'layer-3'];
        let collagesTypes = ['top_1', 'top_2', 'top_3', 'bottom_1', 'layer-full_1', 'top_long_1', 'top_long_2', 'top_long_3'];
        let collageProductType = '';

        for (let i=0; i < products.length; i++) {
            let product = products[i];

            let productType = product.category_group.split('_')[0];
            collageProductType = product.category_group;
            if (topTypes.includes(productType)) {
                collageProductType = 'top';
                if (product.crop_wh && (product.waist_length > 0)) {
                    if ((product.crop_wh[1] / product.waist_length) > 3) {
                        if (((productType === 'layer-3') || (productType === 'layer-2')) && (product.category_group.indexOf('vest') == -1))
                            collageProductType += '_long';
                    }
                }
            } else if (productType === 'bottom' || productType === 'layer-full')
                collageProductType = productType;

            if (currentIndexes.hasOwnProperty(collageProductType)) {
                currentIndexes[collageProductType] += 1;
            } else
                currentIndexes[collageProductType] = 1;

            if (currentMainIndexes.hasOwnProperty(productType)) {
                currentMainIndexes[productType] += 1;
            } else
                currentMainIndexes[productType] = 1;

            product.collageType = collageProductType + '_' + currentIndexes[collageProductType];
            product.mainCategoryType = productType + '_' + currentMainIndexes[productType];
            if (collagesTypes.includes(product.collageType))
                result.push(product.collageType);
        }
        return result;
    }

    getTemplate(templateCode, layerTypes) {
        let templates = {
            "fashion": [
                {
                    "template": ['top_1', 'bottom_1'],
                    "data": {
                        "top_1": {"left": 37, "right": 63, "top": 0, "bottom": 60, "positionx": "center", "positiony": "top", "zindex": 2},
                        "bottom_1": {"left": 8, "right": 33, "top": 25, "bottom": 100, "positionx": "right", "positiony": "top", "zindex": 1},
                        "bottom_shorts": {"left": 17, "right": 38, "top": 35, "bottom": 100, "positionx": "right", "positiony": "top", "zindex": 1},
                        "bottom_skirt": {"left": 17, "right": 38, "top": 35, "bottom": 100, "positionx": "right", "positiony": "top", "zindex": 1},
                        "accessory_1": {"left": 70, "right": 80, "top": 0, "bottom": 20, "positionx": "left", "positiony": "bottom", "zindex": 4},
                        "accessory_1_squared": {"left": 70, "right": 85, "top": 5, "bottom": 20, "positionx": "left", "positiony": "bottom", "zindex": 4},
                        "accessory_1_horizontal": {"left": 70, "right": 90, "top": 10, "bottom": 20, "positionx": "left", "positiony": "bottom", "zindex": 4},
                        "accessory_tie": {"left": 33, "right": 43, "top": 0, "bottom": 35, "positionx": "left", "positiony": "top", "zindex": 6},
                        "belt_1": {"left": 36, "right": 63, "top": 65, "bottom": 75, "positionx": "center", "positiony": "top", "zindex": 5},
                        "bag_1": {"left": 60, "right": 90, "top": 35, "bottom": 70, "positionx": "center", "positiony": "bottom", "zindex": 5},
                        "shoes_1": {"left": 40, "right": 65, "top": 70, "bottom": 100, "positionx": "left", "positiony": "bottom", "zindex": 5},
                        "hat_1": {"left": 10, "right": 30, "top": 0, "bottom": 30, "positionx": "center", "positiony": "top", "zindex": 5},
                    }
                },
                {
                    "template": ['top_1', 'top_2', 'bottom_1'],
                    "data": {
                        "top_1": {"left": 60, "right": 85, "top": 25, "bottom": 85, "positionx": "left", "positiony": "top", "zindex": 3},
                        "top_2": {"left": 37, "right": 62, "top": 0, "bottom": 60, "positionx": "center", "positiony": "top", "zindex": 2},
                        "bottom_1": {"left": 14, "right": 38, "top": 25, "bottom": 100, "positionx": "center", "positiony": "top", "zindex": 1},
                        "bottom_shorts": {"left": 17, "right": 38, "top": 35, "bottom": 100, "positionx": "right", "positiony": "top", "zindex": 1},
                        "bottom_skirt": {"left": 17, "right": 38, "top": 35, "bottom": 100, "positionx": "right", "positiony": "top", "zindex": 1},
                        "accessory_1": {"left": 70, "right": 80, "top": 0, "bottom": 20, "positionx": "left", "positiony": "bottom", "zindex": 4},
                        "accessory_1_squared": {"left": 70, "right": 85, "top": 5, "bottom": 20, "positionx": "left", "positiony": "bottom", "zindex": 4},
                        "accessory_1_horizontal": {"left": 70, "right": 90, "top": 10, "bottom": 20, "positionx": "left", "positiony": "bottom", "zindex": 4},
                        "accessory_tie": {"left": 33, "right": 43, "top": 0, "bottom": 35, "positionx": "left", "positiony": "top", "zindex": 6},
                        "belt_1": {"left": 36, "right": 63, "top": 65, "bottom": 75, "positionx": "center", "positiony": "top", "zindex": 5},
                        "bag_1": {"left": 70, "right": 100, "top": 65, "bottom": 100, "positionx": "center", "positiony": "bottom", "zindex": 5},
                        "shoes_1": {"left": 40, "right": 65, "top": 70, "bottom": 100, "positionx": "left", "positiony": "bottom", "zindex": 5},
                        "hat_1": {"left": 10, "right": 30, "top": 0, "bottom": 30, "positionx": "center", "positiony": "top", "zindex": 5},
                    }
                },
                {
                    "template": ['top_1', 'top_long_1', 'bottom_1'],
                    "data": {
                        "top_long_1": {"left": 75, "right": 100, "top": 0, "bottom": 90, "positionx": "left", "positiony": "top", "zindex": 2},
                        "top_1": {"left": 33, "right": 59, "top": 0, "bottom": 60, "positionx": "center", "positiony": "top", "zindex": 3},
                        "bottom_1": {"left": 14, "right": 38, "top": 25, "bottom": 100, "positionx": "center", "positiony": "top", "zindex": 1},
                        "bottom_shorts": {"left": 17, "right": 38, "top": 35, "bottom": 100, "positionx": "right", "positiony": "top", "zindex": 1},
                        "bottom_skirt": {"left": 17, "right": 38, "top": 35, "bottom": 100, "positionx": "right", "positiony": "top", "zindex": 1},
                        "accessory_1": {"left": 25, "right": 35, "top": 0, "bottom": 20, "positionx": "right", "positiony": "bottom", "zindex": 4},
                        "accessory_1_squared": {"left": 20, "right": 35, "top": 5, "bottom": 20, "positionx": "right", "positiony": "bottom", "zindex": 4},
                        "accessory_1_horizontal": {"left": 15, "right": 35, "top": 10, "bottom": 20, "positionx": "left", "positiony": "bottom", "zindex": 4},

                        "accessory_tie": {"left": 33, "right": 43, "top": 0, "bottom": 35, "positionx": "left", "positiony": "top", "zindex": 6},
                        "belt_1": {"left": 36, "right": 63, "top": 65, "bottom": 75, "positionx": "center", "positiony": "top", "zindex": 5},
                        "bag_1": {"left": 70, "right": 100, "top": 65, "bottom": 100, "positionx": "center", "positiony": "bottom", "zindex": 5},
                        "shoes_1": {"left": 40, "right": 65, "top": 70, "bottom": 100, "positionx": "left", "positiony": "bottom", "zindex": 5},
                    }
                },
                {
                    "template": ['top_long_1', 'top_long_2', 'bottom_1'],
                    "data": {
                        "top_long_1": {"left": 75, "right": 100, "top": 0, "bottom": 90, "positionx": "left", "positiony": "top", "zindex": 2},
                        "top_long_2": {"left": 33, "right": 59, "top": 0, "bottom": 90, "positionx": "center", "positiony": "top", "zindex": 3},
                        "bottom_1": {"left": 19, "right": 38, "top": 35, "bottom": 100, "positionx": "center", "positiony": "top", "zindex": 1},
                        "bottom_shorts": {"left": 17, "right": 38, "top": 35, "bottom": 100, "positionx": "right", "positiony": "top", "zindex": 1},
                        "bottom_skirt": {"left": 17, "right": 38, "top": 35, "bottom": 100, "positionx": "right", "positiony": "top", "zindex": 1},
                        "accessory_1": {"left": 25, "right": 35, "top": 0, "bottom": 20, "positionx": "right", "positiony": "bottom", "zindex": 4},
                        "accessory_1_squared": {"left": 20, "right": 35, "top": 5, "bottom": 20, "positionx": "right", "positiony": "bottom", "zindex": 4},
                        "accessory_1_horizontal": {"left": 15, "right": 35, "top": 10, "bottom": 20, "positionx": "left", "positiony": "bottom", "zindex": 4},

                        "accessory_tie": {"left": 33, "right": 43, "top": 0, "bottom": 35, "positionx": "left", "positiony": "top", "zindex": 6},
                        "belt_1": {"left": 36, "right": 63, "top": 65, "bottom": 75, "positionx": "center", "positiony": "top", "zindex": 5},
                        "bag_1": {"left": 70, "right": 100, "top": 65, "bottom": 100, "positionx": "center", "positiony": "bottom", "zindex": 5},
                        "shoes_1": {"left": 40, "right": 65, "top": 70, "bottom": 100, "positionx": "left", "positiony": "bottom", "zindex": 5},
                    }
                },
                {
                    "template": ['top_1', 'top_2', 'top_3', 'bottom_1'],
                    "data": {
                        "top_1": {"left": 75, "right": 100, "top": 25, "bottom": 85, "positionx": "left", "positiony": "top", "zindex": 4},
                        "top_2": {"left": 42, "right": 67, "top": 5, "bottom": 65, "positionx": "left", "positiony": "top", "zindex": 3},
                        "top_3": {"left": 0, "right": 25, "top": 0, "bottom": 60, "positionx": "left", "positiony": "top", "zindex": 1},
                        "bottom_1": {"left": 20, "right": 45, "top": 25, "bottom": 100, "positionx": "center", "positiony": "bottom", "zindex": 2},
                        "bottom_shorts": {"left": 25, "right": 45, "top": 35, "bottom": 100, "positionx": "center", "positiony": "top", "zindex": 2},
                        "bottom_skirt": {"left": 25, "right": 45, "top": 35, "bottom": 100, "positionx": "center", "positiony": "top", "zindex": 2},
                        "accessory_1": {"left": 70, "right": 80, "top": 0, "bottom": 20, "positionx": "left", "positiony": "top", "zindex": 4},
                        "accessory_1_squared": {"left": 70, "right": 85, "top": 5, "bottom": 20, "positionx": "left", "positiony": "top", "zindex": 4},
                        "accessory_1_horizontal": {"left": 70, "right": 90, "top": 10, "bottom": 20, "positionx": "left", "positiony": "top", "zindex": 4},

                        "accessory_tie": {"left": 0, "right": 10, "top": 0, "bottom": 35, "positionx": "left", "positiony": "top", "zindex": 6},
                        "belt_1": {"left": 46, "right": 73, "top": 65, "bottom": 75, "positionx": "center", "positiony": "top", "zindex": 6},
                        "bag_1": {"left": 70, "right": 100, "top": 65, "bottom": 100, "positionx": "center", "positiony": "bottom", "zindex": 5},
                        "shoes_1": {"left": 40, "right": 65, "top": 70, "bottom": 100, "positionx": "left", "positiony": "bottom", "zindex": 5},
                    }
                },
                {
                    "template": ['top_1', 'top_2', 'top_long_1', 'bottom_1'],
                    "data": {
                        "top_long_1": {"left": 75, "right": 100, "top": 0, "bottom": 90, "positionx": "right", "positiony": "top", "zindex": 3},
                        "top_1": {"left": 45, "right": 70, "top": 5, "bottom": 65, "positionx": "left", "positiony": "top", "zindex": 6},
                        "top_2": {"left": 0, "right": 25, "top": 0, "bottom": 60, "positionx": "left", "positiony": "top", "zindex": 2},
                        "bottom_1": {"left": 20, "right": 45, "top": 25, "bottom": 100, "positionx": "center", "positiony": "top", "zindex": 5},
                        "bottom_shorts": {"left": 25, "right": 45, "top": 40, "bottom": 100, "positionx": "center", "positiony": "top", "zindex": 5},
                        "bottom_skirt": {"left": 25, "right": 45, "top": 35, "bottom": 100, "positionx": "center", "positiony": "top", "zindex": 5},

                        "accessory_tie": {"left": 0, "right": 10, "top": 0, "bottom": 35, "positionx": "left", "positiony": "top", "zindex": 6},
                        "belt_1": {"left": 46, "right": 73, "top": 65, "bottom": 75, "positionx": "center", "positiony": "top", "zindex": 6},
                        "bag_1": {"left": 70, "right": 100, "top": 65, "bottom": 100, "positionx": "center", "positiony": "bottom", "zindex": 5},
                        "shoes_1": {"left": 40, "right": 65, "top": 70, "bottom": 100, "positionx": "left", "positiony": "bottom", "zindex": 5},
                    }
                },
                {
                    "template": ['top_1', 'top_long_1', 'top_long_2', 'bottom_1'],
                    "data": {
                        "top_long_1": {"left": 75, "right": 100, "top": 0, "bottom": 90, "positionx": "right", "positiony": "top", "zindex": 3},
                        "top_long_2": {"left": 45, "right": 70, "top": 5, "bottom": 90, "positionx": "left", "positiony": "top", "zindex": 6},
                        "top_1": {"left": 0, "right": 25, "top": 0, "bottom": 60, "positionx": "left", "positiony": "top", "zindex": 2},
                        "bottom_1": {"left": 20, "right": 45, "top": 25, "bottom": 100, "positionx": "center", "positiony": "top", "zindex": 5},
                        "bottom_shorts": {"left": 25, "right": 45, "top": 35, "bottom": 100, "positionx": "center", "positiony": "top", "zindex": 5},
                        "bottom_skirt": {"left": 25, "right": 45, "top": 35, "bottom": 100, "positionx": "center", "positiony": "top", "zindex": 5},
                        "accessory_1": {"left": 60, "right": 70, "top": 0, "bottom": 20, "positionx": "left", "positiony": "top", "zindex": 7},
                        "accessory_1_squared": {"left": 60, "right": 75, "top": 5, "bottom": 20, "positionx": "left", "positiony": "top", "zindex": 7},
                        "accessory_1_horizontal": {"left": 30, "right": 50, "top": 0, "bottom": 10, "positionx": "left", "positiony": "top", "zindex": 7},

                        "accessory_tie": {"left": 0, "right": 10, "top": 0, "bottom": 35, "positionx": "left", "positiony": "top", "zindex": 6},
                        "belt_1": {"left": 0, "right": 27, "top": 65, "bottom": 75, "positionx": "center", "positiony": "top", "zindex": 6},
                        "bag_1": {"left": 70, "right": 100, "top": 65, "bottom": 100, "positionx": "center", "positiony": "bottom", "zindex": 5},
                        "shoes_1": {"left": 40, "right": 65, "top": 70, "bottom": 100, "positionx": "left", "positiony": "bottom", "zindex": 5},
                    }
                },
                //DRESSES

                {
                    "template": ['layer-full_1', 'top_1'],
                    "data": {
                        "layer-full_1": {"left": 37, "right": 63, "top": 0, "bottom": 100, "positionx": "center", "positiony": "top", "zindex": 2},
                        "top_1": {"left": 65, "right": 90, "top": 10, "bottom": 70, "positionx": "left", "positiony": "top", "zindex": 3},
                        "accessory_1": {"left": 23, "right": 33, "top": 22, "bottom": 42, "positionx": "right", "positiony": "center", "zindex": 4},
                        "accessory_1_squared": {"left": 18, "right": 33, "top": 22, "bottom": 37, "positionx": "right", "positiony": "center", "zindex": 4},
                        "accessory_1_horizontal": {"left": 13, "right": 33, "top": 22, "bottom": 32, "positionx": "right", "positiony": "center", "zindex": 4},

                        "accessory_2": {"left": 23, "right": 33, "top": 44, "bottom": 64, "positionx": "right", "positiony": "center", "zindex": 4},
                        "accessory_2_squared": {"left": 18, "right": 33, "top": 49, "bottom": 64, "positionx": "right", "positiony": "center", "zindex": 4},
                        "accessory_2_horizontal": {"left": 13, "right": 33, "top": 44, "bottom": 54, "positionx": "right", "positiony": "center", "zindex": 4},

                        "hat_1": {"left": 15, "right": 35, "top": 0, "bottom": 30, "positionx": "center", "positiony": "top", "zindex": 2},
                        "bag_1": {"left": 10, "right": 40, "top": 65, "bottom": 100, "positionx": "center", "positiony": "bottom", "zindex": 5},
                        "shoes_1": {"left": 60, "right": 85, "top": 70, "bottom": 100, "positionx": "left", "positiony": "bottom", "zindex": 5},
                    }
                },
                {
                    "template": ['layer-full_1', 'top_1', 'top_2'],
                    "data": {
                        "layer-full_1": {"left": 37, "right": 63, "top": 0, "bottom": 100, "positionx": "center", "positiony": "top", "zindex": 2},
                        "top_1": {"left": 70, "right": 95, "top": 10, "bottom": 70, "positionx": "left", "positiony": "top", "zindex": 3},
                        "top_2": {"left": 15, "right": 40, "top": 10, "bottom": 70, "positionx": "left", "positiony": "top", "zindex": 1},
                        "bag_1": {"left": 10, "right": 40, "top": 65, "bottom": 100, "positionx": "center", "positiony": "bottom", "zindex": 5},
                        "shoes_1": {"left": 60, "right": 85, "top": 70, "bottom": 100, "positionx": "left", "positiony": "bottom", "zindex": 5},
                        "hat_1": {"left": 0, "right": 18, "top": 0, "bottom": 30, "positionx": "center", "positiony": "top", "zindex": 4},
                    }
                },
                {
                    "template": ['layer-full_1', 'top_long_1', 'top_1'],
                    "data": {
                        "layer-full_1": {"left": 37, "right": 63, "top": 0, "bottom": 100, "positionx": "center", "positiony": "top", "zindex": 2},
                        "top_long_1": {"left": 70, "right": 95, "top": 5, "bottom": 90, "positionx": "left", "positiony": "top", "zindex": 3},
                        "top_1": {"left": 5, "right": 30, "top": 10, "bottom": 70, "positionx": "left", "positiony": "top", "zindex": 1},
                        "bag_1": {"left": 10, "right": 40, "top": 65, "bottom": 100, "positionx": "center", "positiony": "bottom", "zindex": 5},
                        "shoes_1": {"left": 60, "right": 85, "top": 70, "bottom": 100, "positionx": "left", "positiony": "bottom", "zindex": 5},
                        "hat_1": {"left": 0, "right": 18, "top": 0, "bottom": 30, "positionx": "center", "positiony": "top", "zindex": 4},
                    }
                },
                {
                    "template": ['layer-full_1', 'top_long_1'],
                    "data": {
                        "layer-full_1": {"left": 37, "right": 63, "top": 0, "bottom": 100, "positionx": "center", "positiony": "top", "zindex": 2},
                        "top_long_1": {"left": 75, "right": 100, "top": 5, "bottom": 95, "positionx": "left", "positiony": "top", "zindex": 3},

                        "accessory_1": {"left": 23, "right": 33, "top": 22, "bottom": 42, "positionx": "right", "positiony": "center", "zindex": 4},
                        "accessory_1_squared": {"left": 18, "right": 33, "top": 22, "bottom": 37, "positionx": "right", "positiony": "center", "zindex": 4},
                        "accessory_1_horizontal": {"left": 13, "right": 33, "top": 22, "bottom": 32, "positionx": "right", "positiony": "center", "zindex": 4},

                        "accessory_2": {"left": 23, "right": 33, "top": 44, "bottom": 64, "positionx": "right", "positiony": "center", "zindex": 4},
                        "accessory_2_squared": {"left": 18, "right": 33, "top": 49, "bottom": 64, "positionx": "right", "positiony": "center", "zindex": 4},
                        "accessory_2_horizontal": {"left": 13, "right": 33, "top": 44, "bottom": 54, "positionx": "right", "positiony": "center", "zindex": 4},

                        "hat_1": {"left": 15, "right": 35, "top": 0, "bottom": 30, "positionx": "center", "positiony": "top", "zindex": 2},
                        "belt_1": {"left": 10, "right": 37, "top": 65, "bottom": 75, "positionx": "center", "positiony": "top", "zindex": 5},
                        "bag_1": {"left": 10, "right": 40, "top": 65, "bottom": 100, "positionx": "center", "positiony": "bottom", "zindex": 5},
                        "shoes_1": {"left": 60, "right": 85, "top": 70, "bottom": 100, "positionx": "left", "positiony": "bottom", "zindex": 5},
                    }
                }
            ],

            /*------------------------------KIDS--------------------------*/
            "kids": [
                {
                    "template": ['top_1', 'bottom_1'],
                    "data": {
                        "top_1": {"left": 37, "right": 63, "top": 0, "bottom": 60, "positionx": "center", "positiony": "top", "zindex": 2},
                        "bottom_1": {"left": 13, "right": 33, "top": 35, "bottom": 100, "positionx": "right", "positiony": "top", "zindex": 1},
                        "bottom_shorts": {"left": 17, "right": 38, "top": 35, "bottom": 100, "positionx": "right", "positiony": "top", "zindex": 1},
                        "bottom_skirt": {"left": 17, "right": 38, "top": 35, "bottom": 100, "positionx": "right", "positiony": "top", "zindex": 1},
                        "accessory_1": {"left": 70, "right": 80, "top": 0, "bottom": 20, "positionx": "left", "positiony": "bottom", "zindex": 4},
                        "accessory_1_squared": {"left": 70, "right": 85, "top": 5, "bottom": 20, "positionx": "left", "positiony": "bottom", "zindex": 4},
                        "accessory_1_horizontal": {"left": 70, "right": 90, "top": 10, "bottom": 20, "positionx": "left", "positiony": "bottom", "zindex": 4},
                        "accessory_tie": {"left": 33, "right": 43, "top": 0, "bottom": 35, "positionx": "left", "positiony": "top", "zindex": 6},
                        "belt_1": {"left": 36, "right": 63, "top": 65, "bottom": 75, "positionx": "center", "positiony": "top", "zindex": 5},
                        "bag_1": {"left": 60, "right": 90, "top": 35, "bottom": 70, "positionx": "center", "positiony": "bottom", "zindex": 5},
                        "shoes_1": {"left": 40, "right": 65, "top": 70, "bottom": 100, "positionx": "left", "positiony": "bottom", "zindex": 5},
                        "hat_1": {"left": 10, "right": 30, "top": 0, "bottom": 30, "positionx": "center", "positiony": "top", "zindex": 5},
                    }
                },
                {
                    "template": ['top_1', 'top_2', 'bottom_1'],
                    "data": {
                        "top_1": {"left": 60, "right": 85, "top": 25, "bottom": 85, "positionx": "left", "positiony": "top", "zindex": 3},
                        "top_2": {"left": 37, "right": 62, "top": 0, "bottom": 60, "positionx": "center", "positiony": "top", "zindex": 2},
                        "bottom_1": {"left": 19, "right": 38, "top": 35, "bottom": 100, "positionx": "center", "positiony": "top", "zindex": 1},
                        "bottom_shorts": {"left": 17, "right": 38, "top": 35, "bottom": 100, "positionx": "right", "positiony": "top", "zindex": 1},
                        "bottom_skirt": {"left": 17, "right": 38, "top": 35, "bottom": 100, "positionx": "right", "positiony": "top", "zindex": 1},
                        "accessory_1": {"left": 70, "right": 80, "top": 0, "bottom": 20, "positionx": "left", "positiony": "bottom", "zindex": 4},
                        "accessory_1_squared": {"left": 70, "right": 85, "top": 5, "bottom": 20, "positionx": "left", "positiony": "bottom", "zindex": 4},
                        "accessory_1_horizontal": {"left": 70, "right": 90, "top": 10, "bottom": 20, "positionx": "left", "positiony": "bottom", "zindex": 4},
                        "accessory_tie": {"left": 33, "right": 43, "top": 0, "bottom": 35, "positionx": "left", "positiony": "top", "zindex": 6},
                        "belt_1": {"left": 36, "right": 63, "top": 65, "bottom": 75, "positionx": "center", "positiony": "top", "zindex": 5},
                        "bag_1": {"left": 70, "right": 100, "top": 65, "bottom": 100, "positionx": "center", "positiony": "bottom", "zindex": 5},
                        "shoes_1": {"left": 40, "right": 65, "top": 70, "bottom": 100, "positionx": "left", "positiony": "bottom", "zindex": 5},
                        "hat_1": {"left": 10, "right": 30, "top": 0, "bottom": 30, "positionx": "center", "positiony": "top", "zindex": 5},
                    }
                },
                {
                    "template": ['top_1', 'top_long_1', 'bottom_1'],
                    "data": {
                        "top_long_1": {"left": 75, "right": 100, "top": 0, "bottom": 90, "positionx": "left", "positiony": "top", "zindex": 2},
                        "top_1": {"left": 33, "right": 59, "top": 0, "bottom": 60, "positionx": "center", "positiony": "top", "zindex": 3},
                        "bottom_1": {"left": 19, "right": 38, "top": 35, "bottom": 100, "positionx": "center", "positiony": "top", "zindex": 1},
                        "bottom_shorts": {"left": 17, "right": 38, "top": 35, "bottom": 100, "positionx": "right", "positiony": "top", "zindex": 1},
                        "bottom_skirt": {"left": 17, "right": 38, "top": 35, "bottom": 100, "positionx": "right", "positiony": "top", "zindex": 1},
                        "accessory_1": {"left": 25, "right": 35, "top": 0, "bottom": 20, "positionx": "right", "positiony": "bottom", "zindex": 4},
                        "accessory_1_squared": {"left": 20, "right": 35, "top": 5, "bottom": 20, "positionx": "right", "positiony": "bottom", "zindex": 4},
                        "accessory_1_horizontal": {"left": 15, "right": 35, "top": 10, "bottom": 20, "positionx": "left", "positiony": "bottom", "zindex": 4},

                        "accessory_tie": {"left": 33, "right": 43, "top": 0, "bottom": 35, "positionx": "left", "positiony": "top", "zindex": 6},
                        "belt_1": {"left": 36, "right": 63, "top": 65, "bottom": 75, "positionx": "center", "positiony": "top", "zindex": 5},
                        "bag_1": {"left": 70, "right": 100, "top": 65, "bottom": 100, "positionx": "center", "positiony": "bottom", "zindex": 5},
                        "shoes_1": {"left": 40, "right": 65, "top": 70, "bottom": 100, "positionx": "left", "positiony": "bottom", "zindex": 5},
                    }
                },
                {
                    "template": ['top_long_1', 'top_long_2', 'bottom_1'],
                    "data": {
                        "top_long_1": {"left": 75, "right": 100, "top": 0, "bottom": 90, "positionx": "left", "positiony": "top", "zindex": 2},
                        "top_long_2": {"left": 33, "right": 59, "top": 0, "bottom": 90, "positionx": "center", "positiony": "top", "zindex": 3},
                        "bottom_1": {"left": 19, "right": 38, "top": 35, "bottom": 100, "positionx": "center", "positiony": "top", "zindex": 1},
                        "bottom_shorts": {"left": 17, "right": 38, "top": 35, "bottom": 100, "positionx": "right", "positiony": "top", "zindex": 1},
                        "bottom_skirt": {"left": 17, "right": 38, "top": 35, "bottom": 100, "positionx": "right", "positiony": "top", "zindex": 1},
                        "accessory_1": {"left": 25, "right": 35, "top": 0, "bottom": 20, "positionx": "right", "positiony": "bottom", "zindex": 4},
                        "accessory_1_squared": {"left": 20, "right": 35, "top": 5, "bottom": 20, "positionx": "right", "positiony": "bottom", "zindex": 4},
                        "accessory_1_horizontal": {"left": 15, "right": 35, "top": 10, "bottom": 20, "positionx": "left", "positiony": "bottom", "zindex": 4},

                        "accessory_tie": {"left": 33, "right": 43, "top": 0, "bottom": 35, "positionx": "left", "positiony": "top", "zindex": 6},
                        "belt_1": {"left": 36, "right": 63, "top": 65, "bottom": 75, "positionx": "center", "positiony": "top", "zindex": 5},
                        "bag_1": {"left": 70, "right": 100, "top": 65, "bottom": 100, "positionx": "center", "positiony": "bottom", "zindex": 5},
                        "shoes_1": {"left": 40, "right": 65, "top": 70, "bottom": 100, "positionx": "left", "positiony": "bottom", "zindex": 5},
                    }
                },
                {
                    "template": ['top_1', 'top_2', 'top_3', 'bottom_1'],
                    "data": {
                        "top_1": {"left": 75, "right": 100, "top": 25, "bottom": 85, "positionx": "left", "positiony": "top", "zindex": 4},
                        "top_2": {"left": 42, "right": 67, "top": 5, "bottom": 65, "positionx": "left", "positiony": "top", "zindex": 3},
                        "top_3": {"left": 0, "right": 25, "top": 0, "bottom": 60, "positionx": "left", "positiony": "top", "zindex": 1},
                        "bottom_1": {"left": 25, "right": 45, "top": 35, "bottom": 100, "positionx": "center", "positiony": "bottom", "zindex": 2},
                        "bottom_shorts": {"left": 25, "right": 45, "top": 35, "bottom": 100, "positionx": "center", "positiony": "top", "zindex": 2},
                        "bottom_skirt": {"left": 25, "right": 45, "top": 35, "bottom": 100, "positionx": "center", "positiony": "top", "zindex": 2},

                        "accessory_tie": {"left": 0, "right": 10, "top": 0, "bottom": 35, "positionx": "left", "positiony": "top", "zindex": 6},
                        "belt_1": {"left": 46, "right": 73, "top": 65, "bottom": 75, "positionx": "center", "positiony": "top", "zindex": 6},
                        "bag_1": {"left": 70, "right": 100, "top": 65, "bottom": 100, "positionx": "center", "positiony": "bottom", "zindex": 5},
                        "shoes_1": {"left": 40, "right": 65, "top": 70, "bottom": 100, "positionx": "left", "positiony": "bottom", "zindex": 5},
                    }
                },
                {
                    "template": ['top_1', 'top_2', 'top_long_1', 'bottom_1'],
                    "data": {
                        "top_long_1": {"left": 75, "right": 100, "top": 0, "bottom": 90, "positionx": "right", "positiony": "top", "zindex": 3},
                        "top_1": {"left": 45, "right": 70, "top": 5, "bottom": 65, "positionx": "left", "positiony": "top", "zindex": 6},
                        "top_2": {"left": 0, "right": 25, "top": 0, "bottom": 60, "positionx": "left", "positiony": "top", "zindex": 2},
                        "bottom_1": {"left": 25, "right": 45, "top": 35, "bottom": 100, "positionx": "center", "positiony": "top", "zindex": 5},
                        "bottom_shorts": {"left": 25, "right": 45, "top": 35, "bottom": 100, "positionx": "center", "positiony": "top", "zindex": 5},
                        "bottom_skirt": {"left": 25, "right": 45, "top": 35, "bottom": 100, "positionx": "center", "positiony": "top", "zindex": 5},

                        "accessory_tie": {"left": 0, "right": 10, "top": 0, "bottom": 35, "positionx": "left", "positiony": "top", "zindex": 6},
                        "belt_1": {"left": 46, "right": 73, "top": 65, "bottom": 75, "positionx": "center", "positiony": "top", "zindex": 6},
                        "bag_1": {"left": 70, "right": 100, "top": 65, "bottom": 100, "positionx": "center", "positiony": "bottom", "zindex": 5},
                        "shoes_1": {"left": 40, "right": 65, "top": 70, "bottom": 100, "positionx": "left", "positiony": "bottom", "zindex": 5},
                    }
                },
                {
                    "template": ['top_1', 'top_long_1', 'top_long_2', 'bottom_1'],
                    "data": {
                        "top_long_1": {"left": 75, "right": 100, "top": 0, "bottom": 90, "positionx": "right", "positiony": "top", "zindex": 3},
                        "top_long_2": {"left": 45, "right": 70, "top": 5, "bottom": 90, "positionx": "left", "positiony": "top", "zindex": 6},
                        "top_1": {"left": 0, "right": 25, "top": 0, "bottom": 60, "positionx": "left", "positiony": "top", "zindex": 2},
                        "bottom_1": {"left": 25, "right": 45, "top": 35, "bottom": 100, "positionx": "center", "positiony": "top", "zindex": 5},
                        "bottom_shorts": {"left": 25, "right": 45, "top": 35, "bottom": 100, "positionx": "center", "positiony": "top", "zindex": 5},
                        "bottom_skirt": {"left": 25, "right": 45, "top": 35, "bottom": 100, "positionx": "center", "positiony": "top", "zindex": 5},
                        "accessory_tie": {"left": 0, "right": 10, "top": 0, "bottom": 35, "positionx": "left", "positiony": "top", "zindex": 6},
                        "belt_1": {"left": 0, "right": 27, "top": 65, "bottom": 75, "positionx": "center", "positiony": "top", "zindex": 6},
                        "bag_1": {"left": 70, "right": 100, "top": 65, "bottom": 100, "positionx": "center", "positiony": "bottom", "zindex": 5},
                        "shoes_1": {"left": 40, "right": 65, "top": 70, "bottom": 100, "positionx": "left", "positiony": "bottom", "zindex": 5},
                    }
                },
                //DRESSES

                {
                    "template": ['layer-full_1', 'top_1'],
                    "data": {
                        "layer-full_1": {"left": 37, "right": 63, "top": 0, "bottom": 100, "positionx": "center", "positiony": "top", "zindex": 2},
                        "top_1": {"left": 65, "right": 90, "top": 10, "bottom": 70, "positionx": "left", "positiony": "top", "zindex": 3},
                        "accessory_1": {"left": 23, "right": 33, "top": 22, "bottom": 42, "positionx": "right", "positiony": "center", "zindex": 4},
                        "accessory_1_squared": {"left": 18, "right": 33, "top": 22, "bottom": 37, "positionx": "right", "positiony": "center", "zindex": 4},
                        "accessory_1_horizontal": {"left": 13, "right": 33, "top": 22, "bottom": 32, "positionx": "right", "positiony": "center", "zindex": 4},

                        "accessory_2": {"left": 23, "right": 33, "top": 44, "bottom": 64, "positionx": "right", "positiony": "center", "zindex": 4},
                        "accessory_2_squared": {"left": 18, "right": 33, "top": 49, "bottom": 64, "positionx": "right", "positiony": "center", "zindex": 4},
                        "accessory_2_horizontal": {"left": 13, "right": 33, "top": 44, "bottom": 54, "positionx": "right", "positiony": "center", "zindex": 4},

                        "hat_1": {"left": 15, "right": 35, "top": 0, "bottom": 30, "positionx": "center", "positiony": "top", "zindex": 2},
                        "bag_1": {"left": 10, "right": 40, "top": 65, "bottom": 100, "positionx": "center", "positiony": "bottom", "zindex": 5},
                        "shoes_1": {"left": 60, "right": 85, "top": 70, "bottom": 100, "positionx": "left", "positiony": "bottom", "zindex": 5},
                    }
                },
                {
                    "template": ['layer-full_1', 'top_1', 'top_2'],
                    "data": {
                        "layer-full_1": {"left": 37, "right": 63, "top": 0, "bottom": 100, "positionx": "center", "positiony": "top", "zindex": 2},
                        "top_1": {"left": 70, "right": 95, "top": 10, "bottom": 70, "positionx": "left", "positiony": "top", "zindex": 3},
                        "top_2": {"left": 15, "right": 40, "top": 10, "bottom": 70, "positionx": "left", "positiony": "top", "zindex": 1},
                        "bag_1": {"left": 10, "right": 40, "top": 65, "bottom": 100, "positionx": "center", "positiony": "bottom", "zindex": 5},
                        "shoes_1": {"left": 60, "right": 85, "top": 70, "bottom": 100, "positionx": "left", "positiony": "bottom", "zindex": 5},
                        "hat_1": {"left": 0, "right": 18, "top": 0, "bottom": 30, "positionx": "center", "positiony": "top", "zindex": 4},
                    }
                },
                {
                    "template": ['layer-full_1', 'top_long_1', 'top_1'],
                    "data": {
                        "layer-full_1": {"left": 37, "right": 63, "top": 0, "bottom": 100, "positionx": "center", "positiony": "top", "zindex": 2},
                        "top_long_1": {"left": 70, "right": 95, "top": 5, "bottom": 90, "positionx": "left", "positiony": "top", "zindex": 3},
                        "top_1": {"left": 5, "right": 30, "top": 10, "bottom": 70, "positionx": "left", "positiony": "top", "zindex": 1},
                        "bag_1": {"left": 10, "right": 40, "top": 65, "bottom": 100, "positionx": "center", "positiony": "bottom", "zindex": 5},
                        "shoes_1": {"left": 60, "right": 85, "top": 70, "bottom": 100, "positionx": "left", "positiony": "bottom", "zindex": 5},
                        "hat_1": {"left": 0, "right": 18, "top": 0, "bottom": 30, "positionx": "center", "positiony": "top", "zindex": 4},
                    }
                },
                {
                    "template": ['layer-full_1', 'top_long_1'],
                    "data": {
                        "layer-full_1": {"left": 37, "right": 63, "top": 0, "bottom": 100, "positionx": "center", "positiony": "top", "zindex": 2},
                        "top_long_1": {"left": 75, "right": 100, "top": 5, "bottom": 95, "positionx": "left", "positiony": "top", "zindex": 3},

                        "accessory_1": {"left": 23, "right": 33, "top": 22, "bottom": 42, "positionx": "right", "positiony": "center", "zindex": 4},
                        "accessory_1_squared": {"left": 18, "right": 33, "top": 22, "bottom": 37, "positionx": "right", "positiony": "center", "zindex": 4},
                        "accessory_1_horizontal": {"left": 13, "right": 33, "top": 22, "bottom": 32, "positionx": "right", "positiony": "center", "zindex": 4},

                        "accessory_2": {"left": 23, "right": 33, "top": 44, "bottom": 64, "positionx": "right", "positiony": "center", "zindex": 4},
                        "accessory_2_squared": {"left": 18, "right": 33, "top": 49, "bottom": 64, "positionx": "right", "positiony": "center", "zindex": 4},
                        "accessory_2_horizontal": {"left": 13, "right": 33, "top": 44, "bottom": 54, "positionx": "right", "positiony": "center", "zindex": 4},

                        "hat_1": {"left": 15, "right": 35, "top": 0, "bottom": 30, "positionx": "center", "positiony": "top", "zindex": 2},
                        "belt_1": {"left": 10, "right": 37, "top": 65, "bottom": 75, "positionx": "center", "positiony": "top", "zindex": 5},
                        "bag_1": {"left": 10, "right": 40, "top": 65, "bottom": 100, "positionx": "center", "positiony": "bottom", "zindex": 5},
                        "shoes_1": {"left": 60, "right": 85, "top": 70, "bottom": 100, "positionx": "left", "positiony": "bottom", "zindex": 5},
                    }
                }
            ]
        }

        let foundTemplate = templates[templateCode].find(templateObj => {
            const sortedTemplate = templateObj.template.slice().sort();
            const sortedLayerTypes = layerTypes.slice().sort();
            return JSON.stringify(sortedTemplate) === JSON.stringify(sortedLayerTypes);
        });

        if (!foundTemplate) {
            let fallBackTemplateCode = ['top_1', 'top_2', 'top_3', 'bottom_1'];
            if (layerTypes.includes('layer-full_1'))
                fallBackTemplateCode = ['layer-full_1', 'top_1'];

            foundTemplate = templates[templateCode].find(templateObj => {
                return JSON.stringify(templateObj.template) === JSON.stringify(fallBackTemplateCode);
            });
        }

        return foundTemplate;
    }

    drawCollage(products, customCode) {
        let layerTypes = this.getRootTypes(products);
        if (!customCode)
            customCode = 'fashion';

        let template = this.getTemplate(customCode, layerTypes);

        let isDemo = false;

        if (template) {
            let templateCopy = JSON.parse(JSON.stringify(template));
            for (let i=0; i < products.length; i++) {
                let product = products[i];
                let direction = '';
                if (product.crop_wh) {
                    if (product.crop_wh[0] > product.crop_wh[1])
                        direction = '_horizontal';
                    else
                        direction = '_vertical';

                    let sizeProportions = product.crop_wh[0] / product.crop_wh[1];
                    if ((sizeProportions > 0.75) && (sizeProportions < 1.25))
                        direction = '_squared';
                }

                let templateData = null;

                if (template['data'].hasOwnProperty(product.category_group))
                    templateData = templateCopy['data'][product.category_group]
                else if (template['data'].hasOwnProperty(product.mainCategoryType + direction))
                    templateData = templateCopy['data'][product.mainCategoryType + direction];
                else if (template['data'].hasOwnProperty(product.mainCategoryType))
                    templateData = templateCopy['data'][product.mainCategoryType];
                else if (template['data'].hasOwnProperty(product.collageType + direction))
                    templateData = templateCopy['data'][product.collageType + direction];
                else if (template['data'].hasOwnProperty(product.collageType))
                    templateData = templateCopy['data'][product.collageType];

                if (templateData) {
                    this.drawProduct(product, templateData);
                    templateData['is_done'] = true;
                }
            }

            if (isDemo) {
                for (const key in templateCopy['data']) {
                    if (!templateCopy['data'][key].hasOwnProperty("is_done") || templateCopy['data'][key]["is_done"] === false) {
                        let demoProduct = {
                            "category_group": key,
                            "id": 0,
                            "waist_length": 0,
                            "picture": "https://www.ledr.com/colours/grey.jpg"
                        }
                        this.drawProduct(demoProduct, templateCopy['data'][key]);
                    }
                }
            }
        }
    }

    drawProduct(product, template) {
        let productImgWrap = document.createElement('div');
        productImgWrap.style.position = 'absolute';
        productImgWrap.classList.add('garderobo-grid-look__product');
        productImgWrap.classList.add('garderobo-grid-look__product_grid_v_3');
        productImgWrap.setAttribute('data-category-collage', product.category_group);
        productImgWrap.setAttribute('data-product-id', product.id);
        productImgWrap.setAttribute('data-waist', product.waist_length);

        productImgWrap.style.left = template.left + '%';
        productImgWrap.style.top = template.top + '%';
        productImgWrap.style.width = (template.right - template.left) + '%';
        productImgWrap.style.height = (template.bottom - template.top) + '%';
        productImgWrap.style.zIndex = template.zindex;
        productImgWrap.style.background = `url(${product.picture}) no-repeat`;
        productImgWrap.style.backgroundPositionX = template.positionx;
        productImgWrap.style.backgroundPositionY = template.positiony;
        if (product.picture === 'https://www.ledr.com/colours/grey.jpg')
            productImgWrap.style.backgroundSize = 'cover';
        else
            productImgWrap.style.backgroundSize = 'contain';

        productImgWrap.setAttribute('data-left', template.left);
        productImgWrap.setAttribute('data-width', template.right - template.left);

        if (product.waist_length && product.crop_wh) {
            let koeff = product.crop_wh[0] / product.waist_length;

            let productWidth = (template.right - template.left) * koeff;
            if ((product.category_group === 'layer-1_ushirt') || (product.category_group === 'layer-1_top'))
                productWidth = productWidth * 0.9;

            if ((product.category_group.indexOf('layer-2') >= 0) && ((product.collageType.indexOf('top_long') >= 0)))
                productWidth = productWidth * 0.9;

            if ((productWidth > 45) && (!product.category_group.startsWith('layer-full')))
                productWidth = 45;
            else if ((productWidth > 50) && (product.category_group.startsWith('layer-full')))
                productWidth = 50;

            let productLeft = template.left + ((template.right - template.left)/2) -  productWidth/2;

            productImgWrap.style.width = productWidth + '%';
            productImgWrap.style.left = productLeft + '%';

            if ((productLeft + productWidth) > 100) {
                productImgWrap.style.left = 'unset';
                productImgWrap.style.right = 0;
            }

            if (productLeft < 0)
                productImgWrap.style.left = 0;
        } else if (product.category_group.startsWith('layer-full')) {
            let productWidth = (template.right - template.left);
            productImgWrap.style.width = productWidth * 1.5 + '%';
        }

        /*if (product.collage_data) {
            productImgWrap.style.left = product.collage_data.left + '%';
            productImgWrap.style.top = product.collage_data.top + '%';
            productImgWrap.style.width = product.collage_data.width + '%';
            productImgWrap.style.height = product.collage_data.height + '%';
            productImgWrap.style.zIndex = product.collage_data.zindex;
            productImgWrap.style.backgroundPositionX = product.collage_data.positionx;
            productImgWrap.style.backgroundPositionY = product.collage_data.positiony;

            productImgWrap.style.backgroundSize = 'contain';

            //productImgWrap.setAttribute('data-left', product.collage_data.left);
            //productImgWrap.setAttribute('data-width', product.collage_data.width);
        }*/

        this.productContainer.appendChild(productImgWrap);
    }
}

export default CollageV3;

//import * as Sentry from "../sentry.init";

let HandledPromise = (executor) => {
    return (new Promise((resolve, reject) => {
        executor(resolve, reject)
    })).catch((e) => {
        //Sentry.captureException(e);
    });
};

export default HandledPromise;
import HandledPromise from "./handled-promise";
//import * as Sentry from "../sentry.init";
import JSON from "circular-json";

let requestsFailing = false;

let grFetch = function(uri, options) {
    let context = this;
    let optionsList = {
        type: 'get',
        data: {},
        dataType: 'json',
        checkInit: true,
        checkSession: true
    };

    if(!options) options = {};
    for(let key in optionsList) {
        options[key] = options.hasOwnProperty(key) ? options[key] : optionsList[key];
    }

    return new HandledPromise((resolve, reject) => {
        let iteration = 0;

        let retry = setTimeout(function retryFetch() {
            try {
                if (context && options.checkInit && !context.isInit) {
                    if (++iteration <= 15) {
                        clearTimeout(retry);
                        retry = setTimeout(retryFetch, iteration*200);
                        return;
                    } else {
                        throw Error('Widget init is too slow');
                    }
                }

                if (requestsFailing)
                    throw Error('Problem connecting to api servers occured');

                let contentType = options.dataType === 'json' ? 'application/json' : null;

                let req = new XMLHttpRequest();

                let fail = (_, details) => {
                    try {
                        // @TODO: Learn what to do :/
                        if (details) throw new Error(details);
                        requestsFailing = true;
                    } catch(e) {
                        reject(e);
                    }
                };

                let _promise = this;
                let stopReqId = 0

                if (options.data && options.data.fallbackTime && options.data.fallback) {
                    stopReqId = setTimeout(() => {
                        req.abort()
                        options.data.fallback.apply(null, [])
                    }, options.data.fallbackTime)
                }

                let statechange = () => {
                    try {
                        if (req.readyState === 4) {
                            switch (req.status) {
                                case 200:
                                    if (stopReqId) {
                                        clearTimeout(stopReqId)
                                    }
                                    /*Sentry.addBreadcrumb({
                                        category: 'http',
                                        message: 'Request for uri: ' + options.type + ' ' + uri + ' response: ' + JSON.stringify(options.data),
                                        level: Sentry.Severity.Info
                                    });*/
                                    resolve(
                                        options.dataType === 'json'
                                            ? JSON.parse(req.responseText)
                                            : req.responseText
                                    );
                                    break;
                                case 401:
                                    fail.call(_promise, req.errors, 'Session not found');
                                    break;
                                case 403:
                                    fail.call(_promise, null, 'Api key or origin are invalid');
                                    break;
                                default:
                                    fail.call(_promise);
                                    break;
                            }
                        }
                    } catch(e) {
                        reject(e);
                    }
                };

                // Insist on cookies and stuff
                req.withCredentials = true;

                req.addEventListener('readystatechange', statechange);
                req.addEventListener('error', fail);
                req.addEventListener('abort', fail);

                if (options.type === 'get') {
                    let res = [];

                    for (let key in options.data)
                        res.push(encodeURIComponent(key) + '=' + encodeURIComponent(options.data[key]));

                    uri += res.length > 0 ? '?' + res.join('&') : '';
                }

                req.open(options.type, uri, true);

                if (contentType) req.setRequestHeader('Content-Type', contentType);

// #if process.env.NODE_ENV !== 'production'
//                 if(options.type === 'post') {
//                     options.data['debug'] = 1;
//                 }
// #endif

                context.hasOwnProperty('getKeyPromise') && context.getKeyPromise().then(key => {
                    if(options.checkSession) req.setRequestHeader('X-Api-Key', key);

                    (new HandledPromise((resolve, reject) => {
                        if(options.checkSession) context.hasOwnProperty('sessionKey')
                            && context.sessionKey.get().then(sessKey => {
                                if(sessKey) resolve(req.setRequestHeader('Authorization', 'Bearer ' + sessKey));
                                else reject();
                            }).catch((e) => {
                                reject();
                            }) || reject();
                        else resolve();
                    })).then(() => req.send(JSON.stringify(options.data)));

                }).catch(reject);
            } catch(e) {
                reject(e);
            }
        }, context && options.checkInit && !context.isInit ? 100 : 10);
    });
};

window.grFetch = grFetch;

export default grFetch;

import Widget from './class/widget';
import HandledPromise from './class/handled-promise';
import './widget.less';
import './creator.less'; // todo - move to better place

(() => {
    if (document && window && document.body) {
        window._garderoboWidget = new HandledPromise((resolve, reject) => {
            let widget = new Widget();
            resolve(widget.widgetApi);
        });

        window._garderoboWidgetBuyLook = new Promise(function (resolve, reject) {
            window._garderoboWidgetBuyLookResolver = resolve;
        });
    }
})();
